import React, { useState } from "react";
import { useWindowWidth } from '@react-hook/window-size/throttled'

export const MODE_BREAKPOINT = 768;

export const DONOR_INFO_STEP = 0;
export const QR_CODE_STEP = 1;

export const ToolContext = React.createContext();

const STEP_NAMES_FOR_EVENT = [];

STEP_NAMES_FOR_EVENT[DONOR_INFO_STEP] = "Donor Input";
STEP_NAMES_FOR_EVENT[QR_CODE_STEP] = "QR Code Display";

const ToolProvider = ({ bootstrap, children }) => {
  const width = useWindowWidth();
  const mode = (width < MODE_BREAKPOINT) ? "mobile" : "desktop";

  const [ donor, setDonor ] = useState({
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "US",
    email: "",
    phoneNumber: "",
  });

  const [ encryptedData, setEncryptedData] = useState(null);
  const [ step, setStepValue ] = useState(DONOR_INFO_STEP);

  const setStep = nextStep => {
    setStepValue(nextStep);
  };

  const contextValue = {
    mode,
    step,
    setStep,
    donor,
    setDonor,
    encryptedData,
    setEncryptedData,
  };

  return (
    <ToolContext.Provider value={contextValue}>
      {children}
    </ToolContext.Provider>
  )
}

export default ToolProvider;

