import React from "react";
import { times } from "ramda";

import * as UI from "./ui";

const rand = () => Math.floor(Math.random() * 10);

const buildWheel = target => {
  const numbers = times(rand, 100);
  numbers.unshift("0");
  numbers.push(target);
  return numbers;
}

const NumberWheel = ({ target, cardWidth, duration, delay = 0 }) => {
  const numbers = buildWheel(target);

  return (
    <UI.Layout cardWidth={cardWidth}>
      <UI.NumberList length={numbers.length} cardWidth={cardWidth} duration={duration} delay={delay}>
        {numbers.map((n,i) => <UI.NumberCard key={i}>{n}</UI.NumberCard>)}
      </UI.NumberList>
      <UI.Shine />
    </UI.Layout>
  )
};

export default NumberWheel;
