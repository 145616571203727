import { gql } from "@apollo/client";

export const sweepstakesQuery = gql`
  query SweepstakesQuery($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      headline
      description
      prizeDetails
      announcement
      endsAt
      location
      winningTicketDrawnAt
      winnerPresentationUrl
      timezone

      slides {
        id
        caption
        image {
          publicId
          version
          cloudName
          crop
          gravity
          background
        }
      }

      sponsors {
        id
        website
        logo {
          cloudName
          publicId
          version
        }
      }

      schedule {
        id
        review
        open
        closing
        grace
        closed
        drawn
        archived
      }

      nonprofit {
        id
        name

        address {
          line1 
          line2 
          city
          state
          postalCode
        }

        profile {
          id
          description
          website
          newsletterWebsite
          phone
          email
          color

          image {
            publicId
            version
            cloudName
            crop
            gravity
            background
          }
        }
      }

      prize {
        id
        cashAlternative
        winnerSnapshot {
          name
          number
        } 
      }

      additionalPrizes {
        id
        name
        winnerSnapshot {
          name
          number
        } 
      }

      activeFlashContest {
        id
        image {
          publicId
          version
          cloudName
          crop
          gravity
          background
        }
        caption
        announcement
      }
    }
  }
`;

