import { gql } from "@apollo/client";

export const fulfillmentToolQuery = gql`
  query FulfillmentToolQuery($id: SweepstakesSlug!, $token: String!) {
    sweepstakes(id: $id) {
      id
      headline

      slides {
        id
        image {
          publicId
          version
          cloudName
        }
      }

      nonprofit {
        id
        ein
        name

        profile {
          id
          color
        }
      }

      schedule {
        id
        grace
      }
    }

    ticketRequest(id: $id, token: $token) {
      isFulfilled
    }
  }
`;

