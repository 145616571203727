import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Widget = styled.span`
  z-index: 5;
  position: absolute;
  top: -13px;
  left: 28px;
  padding: 14px 20px 10px 13px;
  background: #FF5822;
  border-radius: 2px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;
