import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { Honeybadger } from '@honeybadger-io/react'
import { isNil } from "ramda";

import { ToolContext, DONOR_INFO_STEP } from "./ToolProvider";
import Layout from "../Layout";
import * as UI from "./ui";
import DonorForm from "./DonorForm";
import { fulfillTicketRequestMutation } from "./mutations";
import ClosedContent from "./ClosedContent";

const ERROR = "Uh-oh! Sorry, but something has gone wrong. To try again please submit the form again. We’ve notified our support team of this issue.";

const notifyFailure = (error) => {
  Honeybadger.notify(error);
}

const InputStep = ({ sweepstakes, onComplete }) => {
  const { donor, token, expired } = useContext(ToolContext);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ fulfillTicketRequest, { loading }] = useMutation(fulfillTicketRequestMutation);

  if(expired) {
    return <ClosedContent sweepstakes={sweepstakes} />
  }

  const fulfill = () => {
    setErrorMessage('');

    fulfillTicketRequest({
      variables: {
        token,
        attributes: donor,
      }
    }).then(({data: { fulfillTicketRequest: { error }}}) => {
      if(isNil(error)) {
        onComplete();
      } else {
        notifyFailure(error.message);
        setErrorMessage(ERROR);
      }
    });
  }

  return (
    <Layout.FullWidth fold>
      <DonorForm
        steps={DONOR_INFO_STEP}
        onComplete={fulfill}
        disabled={loading}
      />
      <Layout.FullWidthColumn>
        { errorMessage && <UI.ErrorMessage>{errorMessage}</UI.ErrorMessage> }
      </Layout.FullWidthColumn>
    </Layout.FullWidth>
  );
};

export default InputStep;
