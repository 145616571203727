import styled from "styled-components";
import { HashLink } from "react-router-hash-link"

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const AmoeLink = styled(HashLink)`
  display: block;
  height: 60px;
  color: #000000;
  text-align: center;
  text-decoration: underline;
`;
