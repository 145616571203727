import React, { useEffect, useState } from "react";

export const Scene = ({ from, to, tick, children }) => (
  (from <= tick && tick < to) ? (
    React.cloneElement(children, { length: (to - from) })
  ) : (
    null
  )
);

let timerId;

const Presentation = ({ children, length}) => {
  const [ tick, setTick ] = useState(0);

  useEffect(() => {
    timerId = setInterval(() => {
      setTick(previousTick => previousTick + 1)
    }, 1000)
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    if(tick === length && timerId) {
      clearInterval(timerId);
    }
  }, [tick, length]);

  return React.Children.map(children, child => React.cloneElement(child, { tick }));
};

export default Presentation;
