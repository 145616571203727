import React, { useContext } from "react";

import SweepstakesHeadTags from "../SweepstakesHeadTags";
import GracePeriodGuard from "../GracePeriodGuard";
import { BootParameterContext } from "../../lib/BootParameterProvider";
import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import { donationToolQuery } from "./queries";
import DonationTool from "../DonationTool";

const DonationPage = () => {
  const { level, promo } = useContext(BootParameterContext);

  const { data, loading, error } = useSweepstakesQuery(donationToolQuery, {
    variables: {
      promoCodeName: promo,
    }
  });

  if(loading || error) {
    return null;
  }

  const {
    sweepstakes,
    sweepstakes: {
      nonprofit
    },
    promoCode,
  } = data;

  const donationLevel = sweepstakes.donationLevels.find(donationLevel => (
    donationLevel.level === level
  ));

  return (
    <React.Fragment>
      <SweepstakesHeadTags sweepstakes={sweepstakes} title={`Make a Donation | ${sweepstakes.headline}`} />
      <GracePeriodGuard schedule={sweepstakes.schedule}>
        <DonationTool
          sweepstakes={sweepstakes}
          nonprofit={nonprofit}
          bootstrap={{ donationLevel, promoCode }}
        />
      </GracePeriodGuard>
    </React.Fragment>
  );
};

export default DonationPage;
