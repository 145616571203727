import React from "react";
import { isNil, either, startsWith } from "ramda";

const looksLikeUrl = either(startsWith("http://"), startsWith("https://"));

const DrawingLocation = ({ location }) => {
  if(isNil(location)) {
    return "TBD";
  }

  if(looksLikeUrl(location)) {
    return (
      <a href={location} target="_blank" rel="noopener noreferrer">
        Join Us Online
      </a>
    );
  }

  return location;
}

export default DrawingLocation;
