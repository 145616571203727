import confetti from "canvas-confetti";

const DEFAULTS = {
  particleCount: 150,
  spread: 60,
  startVelocity: 60,
  gravity: 0.4,
};

const bottomLeft = {
  ...DEFAULTS,
  angle: 45,
  origin: { x: 0, y: 1 }
}

const bottomRight = {
  ...DEFAULTS,
  angle: 135,
  origin: { x: 1, y: 1 }
}

const launch = () => {
  setTimeout(() => {
    confetti(bottomLeft);
    confetti(bottomRight);
  }, 500);
}

export default launch;
