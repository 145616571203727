import styled from "styled-components"

export const Layout = styled.div`
  border: #e3e3e3 solid 1px;
  text-align: center;
`

export const Banner = styled.div`
  padding: 30px 20px;
  background-color: #00c19d;
  color: #FFFFFF;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25em;
`;

export const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Section = styled.div`
  flex: 1;
  min-width: 100%;

  border-bottom: #e3e3e3 solid 1px;
  line-height: 1.5em;
  padding: 20px;

  &:last-child {
    border: none;
  }

  @media screen and (min-width: 440px) and (max-width: 768px) {
    border-bottom: none;
    min-width: auto;
    border-right: #e3e3e3 solid 1px;
  }
`;

export const Label = styled.div`
  color: #666;
  margin-bottom: 5px;
`;

export const Value = styled.div`
  font-weight: 700;
  font-size: 1.35em;
`;

export const Location = styled.div`
  font-size: 0.875em;
`;

