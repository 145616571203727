import styled from "styled-components";

export const Form = styled.form`
  margin: auto -5px 20px;
`

export const StripeElementLayout = styled.div`
  position: relative;
  padding: 0 5px;
  margin-bottom: 10px;
`;

export const StripeElement = styled.div`
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 12px 5px;
`;

export const SubstepHeading = styled.h2`
  font-size: 1em;
  margin: 20px auto;
`;

export const ActionButton = styled.input.attrs({
  type: "submit",
})`
  appearance: none;
  background-color: #00C19D;
  border: none;
  border-radius: 2px;
  color: #fff;
  display: block;
  font-size: 1em;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 40px;
  margin: 0 5px 0 auto;

  &:hover {
    background-color: #00755f;
  }

  &:disabled {
    background-color: #cccccc;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0 5px;
  }
`

export const ErrorMessage = styled.div`
  color: #fff;
  background-color: #db3a34;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.75em;
  border-radius: 2px;
`
