import React from "react";

import * as UI from "./ui";

const Field = ({id, label, value, onChange, weight, options }) => {
  return (
    <UI.FieldLayout weight={weight}>
      <UI.FieldBody>
        <UI.Select name={id} id={id} type="text" value={value} onChange={onChange}>
          { options.map(option => <option key={option.id} value={option.id}>{option.name}</option> )}
        </UI.Select>
        <UI.Label htmlFor={id} empty={!value}>{label}</UI.Label>
      </UI.FieldBody>
    </UI.FieldLayout>
  );
}

export default Field;

