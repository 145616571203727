import React, { useState } from "react";

import * as UI from "./ui";
import Copy from '../Copy';

const PrizeDetails = ({ prizeDetails }) => {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  if(!open) {
    return (
      <UI.OpenPrizeDetails onClick={onClick}>
        See Prize Details
      </UI.OpenPrizeDetails>
    )
  }

  return (
    <React.Fragment>
      <UI.Heading>Prize Details</UI.Heading>
      <UI.PrizeDetailsCopy>
        <Copy markdown={prizeDetails} />
      </UI.PrizeDetailsCopy>
    </React.Fragment>
  );
}

export default PrizeDetails;
