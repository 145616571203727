import { useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { mergeDeepRight } from "ramda";

import { BootParameterContext } from "./BootParameterProvider";

const useSweepstakesQuery = (query, options = {}) => {
  const { id } = useContext(BootParameterContext);

  const sweepstakesVariables = { variables: { id } };
  const newOptions = mergeDeepRight(sweepstakesVariables, options);

  return useLazyQuery(query, newOptions)
}

export default useSweepstakesQuery;
