import React from "react";
import { Link } from 'react-router-dom';

import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import { pickTextColor } from "../../lib/color";
import Layout from "../Layout";
import Subheader from "../Subheader";
import { headerQuery } from "./queries";
import * as UI from "./ui";

const Header = ({ share }) => {
  const { data, loading, error } = useSweepstakesQuery(headerQuery);

  if(loading) {
    return <p>Loading</p>;
  }

  if(error) {
    return <p>Error</p>
  }

  const {
    sweepstakes: {
      totalDonations,
      threshold,
      nonprofit,
    }
  } = data;

  const styles = {
    backgroundColor: nonprofit.profile.color,
    color: pickTextColor({ background: nonprofit.profile.color, light: '#fff', dark: '#000' }),
  };

  return (
    <UI.Header id="header">
      <UI.Nonprofit style={styles}>
        <Layout.FullWidth alignItems="center">
          <Layout.FixedColumn span={3} breakpoint="belowTablet" breakwidth="auto">
            <Link to="/">
              <UI.Logo {...nonprofit.profile.logo} alt={nonprofit.name} crop="fit" height="75" width="160" dpr="3.0" quality="auto:best" />
            </Link>
          </Layout.FixedColumn>
          <Layout.FlexibleColumn span={[9,17]} breakpoint="belowTablet" breakwidth="auto">
            <UI.Name>{nonprofit.name}</UI.Name>
            <UI.Cause>{nonprofit.profile.cause}</UI.Cause>
          </Layout.FlexibleColumn>
        </Layout.FullWidth>
      </UI.Nonprofit>
      <Subheader totalDonations={totalDonations} threshold={threshold} />
    </UI.Header>
  );
}

export default Header;
