import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Name = styled.div`
  font-weight: 700;
  font-size: 1.25em;
`;

export const Address = styled.div`
  font-size: 0.875em;
  color: #555555;
`;

export const Contact = styled.div`
  font-weight: 700;
  font-size: 0.875em;
  line-height: 1.5em;
`;

