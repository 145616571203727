import styled from "styled-components";

export const Layout = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Body = styled.div`
  position: relative;
  z-index: 0;
  flex: 1 1 auto;
`;

