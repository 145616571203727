import React from "react";
import { Link } from "react-router-dom"

import Layout from "../Layout";
import * as UI from "./ui";

const Receipt = ({ sweepstakes, mailinTicketRequest }) => (
  <React.Fragment>
    <Layout.FlexibleColumn span={[7,15]} breakpoint="belowLaptop" spaced>
      <UI.Receipt>
        {`To request a sweepstakes entry without a donation in The ${sweepstakes.prize.name} Sweepstakes, print and mail this form with postage affixed to:`}
        <UI.Address>
          <p>Sweepstakes Entry</p>
          <p>6525 Gunpark Drive, Suite 370-505</p>
          <p>Boulder, CO 80301</p>
        </UI.Address>
        Each entry request mailer form must be mailed individually.  In order to fulfill your entry request, the mailer form must be postmarked by the Sweepstakes end date, and must be received at the above address within five (5) business days thereafter. A receipt with the sweepstakes entry will be emailed to you. Thank you for participating. <strong> This sweepstakes in a fundraising campaign for our nonprofit organization. Please consider supporting our cause with a donation.</strong> <Link to="/donate">Please consider supporting our cause with a donation.</Link>
      </UI.Receipt>

      <UI.DownloadButton href={mailinTicketRequest.downloadUrl} target="_blank">Download and Print</UI.DownloadButton>
    </Layout.FlexibleColumn>
    <Layout.FixedColumn span={5} breakpoint="belowLaptop" spaced />
  </React.Fragment>
);

export default Receipt;
