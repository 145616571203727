import React from "react";
import { Helmet } from 'react-helmet';

const GoogleTagManager = ({ containerId }) => {
  if(!containerId) {
    return null;
  }

  return (
    <React.Fragment>
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-${containerId}');`}
        </script>
      </Helmet>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=GTM-${containerId}`}
          style={{height: 0, width: 0, display: "none", visibility: "hidden"}}
          title="gtm"
        />
      </noscript>
    </React.Fragment>
  )
}

export default GoogleTagManager;

