import React, { useState, useEffect, useContext } from "react";

import { ToolContext, DONATION_LEVEL_STEP, DONOR_INFO_STEP, PAYMENT_STEP, RECEIPT_STEP } from "./ToolProvider";
import * as UI from "./ui";

const GuideStep = ({ currentStep, thisStep, setStep, name, max}) => {
  const onClick = () => {
    if(thisStep <= max) {
      setStep(thisStep)
    }
  };

  return (
    <UI.GuideStep active={currentStep === thisStep} onClick={onClick}>
      <UI.GuideStepArrow />
      {name}
    </UI.GuideStep>
  )
}

const Guide = () => {
  const { mode, step, setStep, expired } = useContext(ToolContext);
  const [ max, setMax ] = useState(0);

  useEffect(() => {
    if(step > max) {
      setMax(step);
    }
  }, [max, step]);

  if(step === RECEIPT_STEP || expired) {
    return null;
  }

  return (
    <UI.Guide>
      <GuideStep
        currentStep={step}
        thisStep={DONATION_LEVEL_STEP}
        setStep={setStep}
        name="Donation"
        max={max}
      />

      { mode === "mobile" && (
        <GuideStep
          currentStep={step}
          thisStep={DONOR_INFO_STEP}
          setStep={setStep}
          name="Your Info"
          max={max}
        />
      )}

      <GuideStep
        currentStep={step}
        thisStep={PAYMENT_STEP}
        setStep={setStep} name="Review & Pay"
        max={max}
      />
    </UI.Guide>
  );
}

export default Guide;
