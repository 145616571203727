import styled from "styled-components";
import { Link } from 'react-router-dom';


// IE11 requires a nested flex container for the column to grow correctly.
export const LayoutWrwapper = styled.div`
  display: flex;
`;

export const Layout = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Body = styled.div`
  position: relative;
  z-index: 0;
  flex: 1 0 auto;
`;

export const Header = styled.div`
  a {
    font-size: 2em;
    line-height: 0;
    color: #fff;
    text-decoration: none;
  }
`;

export const Nonprofit = styled.div`
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

export const Back = styled(Link)`
  display: block;
  margin-left: -20px;
  padding: 15px 20px;

  :after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right-color: inherit;
    transform: translate(-20%, 1px) scaleY(0.85);
  }
`;

export const Name = styled.div`
  font-size: 1.5em;
  font-weight: 700;
  padding: 10px 0;

  @media screen and (max-width: 767px) {
    font-size: 1em;
  }
`;

export const DonationLevelList = styled.ol`
  display: flex;
  flex-wrap: wrap;
  margin: auto -5px;
`;

export const DonationLevelItem = styled.li`
  min-width: 50%;
  padding: 5px;

  @media screen and (max-width: 1023px) {
    min-width: 25%;
  }

  @media screen and (max-width: 440px) {
    min-width: 50%;
  }
`;

export const FieldLayout = styled.div`
  padding: 0 5px;
  flex: ${props => props.weight || 1};
`;

export const Form = styled.form`
  margin: auto -5px 20px;
  ${FieldLayout} {
    margin-bottom: 10px;
  }
`

export const FieldBody = styled.div`
  border: 1px solid #ddd;
  border-radius: 3px;
  position: relative;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  text-transform: uppercase;
  position: absolute;
  color: #979797;
  top: 5px;
  left: 10px;
  font-size: 0.8125em;
  transform-origin: top left;
  transition: transform 100ms linear;
  transform: ${props => props.empty ? "scale(1.0)" : "scale(0.8)"};
  cursor: text;
  user-select: none;

  input:focus + & {
    transform: scale(0.8);
  }
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  padding: 20px 10px 5px;
  background: none;
  /* This is set to 1em = 16px so that Mobile Safari does not "help" by zooming in. */
  font-size: 1em;
`;

export const Select = styled.select`
  width: 100%;
  border: none;
  padding: 20px 10px 5px;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat calc(100% - 5px) 50%;
  appearance:none;
  /* This is set to 1em = 16px so that Mobile Safari does not "help" by zooming in. */
  font-size: 1em;
`;

export const FieldGroup = styled.div`
  display: flex;
`;

export const SubstepHeading = styled.h2`
  font-size: 1em;
  margin: 20px auto;
`;

export const Guide = styled.div`
  display: flex;
  height: 50px;
`;

export const GuideStepArrow = styled.div`
  height: 50px;
  width: 2px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: -2px;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    height: 0;
    width: 0;
    border-style: solid;
    border-color: #fff;
    border-width: 25px;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    transform: scaleX(50%);
    transform-origin: top left;
  }
`

export const GuideStep = styled.div`
  position: relative;
  background-color: ${props => props.active ? "#dddddd" : "#efefef"};
  font-size: 0.75em;
  text-align: center;
  padding: 20px 10px;
  flex: 1;
  border-right: 1px solid #ccc;
  font-weight: ${props => props.active ? "700" : "500"};
  color: #555;
  text-indent: 2px;

  &:last-child {
    &:after, ${GuideStepArrow} {
      display: none;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    height: 0;
    width: 0;
    border-style: solid;
    border-color: ${props => props.active ? "#dddddd" : "#efefef"};
    border-width: 25px;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    transform: scaleX(50%);
    transform-origin: top left;
    z-index: 1;
  }
`

export const ActionButton = styled.input.attrs({
  type: "submit",
})`
  appearance: none;
  background-color: #00C19D;
  border: none;
  border-radius: 2px;
  color: #fff;
  display: block;
  font-size: 1em;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 40px;
  margin: 0 5px 0 auto;

  &:hover {
    background-color: #00755f;
  }

  &:disabled {
    background-color: #cccccc;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0 5px;
  }
`

export const Legal = styled.div`
  padding: 10px 0;
  margin: 0 5px;
  font-size: 0.75em;
  color: #333333;
  text-align: right;
`

export const ErrorMessage = styled.div`
  color: #fff;
  background-color: #db3a34;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.75em;
  border-radius: 2px;
`
