import styled from "styled-components";

const breakpoints = {
  "belowPhone": 479,
  "phone": 480,
  "belowTablet": 767,
  "tablet": 768,
  "belowLaptop": 1023,
  "laptop": 1024,
};

const FullWidth = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: ${props => props.alignItems || "stretch"};
  box-sizing: border-box;
  flex-wrap: ${props => props.fold? "wrap" : "none"};
  flex-direction: ${props => props.direction || "row"};
`

const FullWidthColumn = styled.div`
  width: 100%;
  padding: 0 10px;
  ${props => props.spaced && "margin-bottom: 20px;"}
`

const FixedColumn = styled.div`
  width: ${props => props.span * 60}px;
  padding: 0 10px;
  ${props => props.spaced && "margin-bottom: 20px;"}

  ${props => props.breakpoint && `
    @media screen and (max-width: ${breakpoints[props.breakpoint]}px) {
      width: ${props.breakwidth || "100%"};
    }
  `}

  ${props => props.showAbove && `
    display: none;

    @media screen and (min-width: ${breakpoints[props.showAbove]}px) {
      display: block;
    }
  `}
`

const FlexibleColumn = styled.div`
  min-width: ${props => props.span[0] * 60}px;
  max-width: ${props => props.span[1] * 60}px;
  ${props => props.spaced && "margin-bottom: 20px;"}
  padding: 0 10px;
  flex: 1;

  ${props => props.breakpoint && `
    @media screen and (max-width: ${breakpoints[props.breakpoint]}px) {
      width: ${props.breakwidth || "100%"};
      min-width: auto;
      max-width: auto;
    }
  `}

  ${props => props.showAbove && `
    display: none;

    @media screen and (min-width: ${breakpoints[props.showAbove]}px) {
      display: block;
    }
  `}
`

const Spacer = styled.div`
  flex: 1;
`

const Divider = styled.hr`
  width: 100%;
  margin: 0 0 40px 0;
  height: 0;
  border: none;
  border-bottom: 1px solid #d6d6d6;
`

const Layout = {
  FullWidth,
  FullWidthColumn,
  FixedColumn,
  FlexibleColumn,
  Spacer,
  Divider,
}

export default Layout;
