import React, { useState } from 'react';

import Copy from '../Copy';
import * as UI from "./ui";

const Item = ({ item }) => {
  const [ visible, setVisible ] = useState(false);

  return (
    <UI.Faq>
      <UI.Question onClick={() => setVisible(!visible)} open={visible}>
        {item.question}
      </UI.Question>
      <UI.Answer open={visible}>
        <Copy markdown={item.answer} />
      </UI.Answer>
    </UI.Faq>
  )
}

export default Item;
