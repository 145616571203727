import React from "react";

import * as UI from "./ui";

const Field = ({id, label, value, type, required, onChange, weight}) => {
  return (
    <UI.FieldLayout weight={weight}>
      <UI.FieldBody>
        <UI.Input name={id} id={id} value={value} onChange={onChange} required={required} type={type} />
        <UI.Label htmlFor={id} empty={!value}>{label}</UI.Label>
      </UI.FieldBody>
    </UI.FieldLayout>
  );
}

export default Field;

