import React from "react";
import { Link } from "react-router-dom";
import useDimensions from "react-use-dimensions";

import Layout from "../Layout";
import Footer from '../Footer';
import Slideshow from "../Slideshow";
import { presentationQuery } from "./queries";
import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import * as UI from "./ui";

const WinnerSlideshow = () => {
  const [slideshowRef, { height, width }] = useDimensions();
  const { data, loading } = useSweepstakesQuery(presentationQuery)

  if(loading) { 
    return null;
  }

  const {
    sweepstakes,
    sweepstakes: {
      nonprofit,
    }
  } = data;

  const styles = {
    backgroundColor: nonprofit.profile.color,
  };

  return (
    <UI.Layout>
      <UI.Header style={styles}>
        <Layout.FullWidth alignItems="center">
          <Layout.FixedColumn span={3} breakpoint="belowTablet" breakwidth="auto">
            <Link to="/">
              <UI.Logo source={nonprofit.profile.logo} alt={nonprofit.name} crop="fit" height="75" width="160" dpr="3.0" quality="auto:best" />
            </Link>
          </Layout.FixedColumn>
          <Layout.FlexibleColumn span={[9,17]} breakpoint="belowTablet" breakwidth="auto">
            <UI.Name>{nonprofit.name}</UI.Name>
            <UI.Cause>{nonprofit.profile.cause}</UI.Cause>
          </Layout.FlexibleColumn>
        </Layout.FullWidth>
      </UI.Header>
      <UI.SlideshowLayout ref={slideshowRef}>
          <Slideshow
            slides={sweepstakes.slides}
            disableCaptions
            disableControls
            height={height}
            width={width}
            aspectRatio={[16,9]}
          />
      </UI.SlideshowLayout>
      <Footer discrete />
    </UI.Layout>
  );
}

export default WinnerSlideshow;
