import React from 'react';

import { faXTwitter } from '@fortawesome/free-brands-svg-icons';

import * as UI from "./ui";

const opts = ['width=500', 'height=500'];

const Twitter = ({ url, message }) => {
  const onClick = () => {
    window.open(`https://twitter.com/intent/tweet?text=${message}&url=${url}`, 'twitter', opts);
  }

  return (
    <UI.Button onClick={onClick}>
      <UI.Icon icon={faXTwitter} />
    </UI.Button>
  );
}

export default Twitter;

