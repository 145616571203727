import React from 'react';

import Layout from "../Layout";
import { Link } from 'react-router-dom';

import * as UI from "./ui";

const SuperFooter = ({ sweepstakes }) => (
  <UI.SuperFooter>
    <Layout.FullWidth fold>
      <Layout.FlexibleColumn span={[7,15]} breakpoint="belowTablet" spaced>
        <UI.Menu>
          <UI.MenuItem>
            <Link to="/">Sweepstakes Home</Link>
          </UI.MenuItem>

          <UI.MenuItem>
            <Link to="/rules">Sweepstakes Rules & Regulations</Link>
          </UI.MenuItem>

          <UI.MenuItem>
            <Link to="/faq">Frequently Asked Questions</Link>
          </UI.MenuItem>
        </UI.Menu>

        <UI.Contact>
          Questions? Contact <a href={`mailto:${sweepstakes.nonprofit.profile.email}`}>{sweepstakes.nonprofit.profile.email}</a>
        </UI.Contact>

        <UI.Legal>
          No purchase or payment necessary to enter this sweepstakes. <UI.TicketRequest to="/amoe">Click here to enter without a donation.</UI.TicketRequest>
        </UI.Legal>
      </Layout.FlexibleColumn>
    </Layout.FullWidth>
  </UI.SuperFooter>
);

export default SuperFooter;
