import { gql } from "@apollo/client";

export const sweepstakesQuery = gql`
  query SweepstakesQuery($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      headline

      slides {
        id
        caption
        image {
          publicId
          version
          cloudName
          crop
          gravity
          background
        }
      }
    }
  }
`;

export const presentationQuery = gql`
  query PresentationQuery($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id

      nonprofit {
        id
        name

        profile {
          id
          cause
          color

          logo {
            publicId
            cloudName
            version
          }
        }
      }

      slides {
        id
        caption
        image {
          publicId
          version
          cloudName
          crop
          gravity
          background
        }
      }

      prize {
        id
        name

        winnerSnapshot {
          name
          location
          number
        }
      }
    }
  }
`;
