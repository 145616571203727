import React from "react";
import ReactDOM from "react-dom";

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.root = document.getElementById('notice');
    this.el = document.createElement('div');
  }

  componentDidMount() {
    this.root.appendChild(this.el);
  }

  componentWillUnmount() {
    this.root.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

const TopNotice = ({ children }) => (
  <Portal>{children}</Portal>
);

export default TopNotice;

