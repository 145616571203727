import React from "react";
import { isNil, path } from "ramda";

import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import { currentSweepstakesQuery } from "./gql";

import * as UI from "./ui";

const CurrentSweepstakesCTA = () => {
  const { data, loading, error } = useSweepstakesQuery(currentSweepstakesQuery);

  if(error || loading) {
    return null;
  }

  const currentSweepstakes = path(["sweepstakes", "nonprofit", "currentSweepstakes"], data);

  if (isNil(currentSweepstakes)) {
    return null;
  }

  return (
    <UI.Layout>
      <UI.Thumbnail
        source={currentSweepstakes.slides[0].image}
        aspectRatio="16:9"
        width="auto"
        dpr="auto"
        fetchFormat="auto"
        responsive
      />
      <UI.Label>
        See the new sweepstakes!
      </UI.Label>
      <UI.Button as="a" href={currentSweepstakes.donationAppUrl}>Enter Now</UI.Button>
    </UI.Layout>
  );
};

export default CurrentSweepstakesCTA;
