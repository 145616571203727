import styled from 'styled-components';
import { Image } from "cloudinary-react";

export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const Nonprofit = styled.div`
  padding: 10px 0;
`;

export const Logo = styled(Image)`
  max-height: 75px;
  max-width: 160px;

  @media screen and (max-width: 768px) {
    max-height: 50px;
  }
`

export const Name = styled.div`
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 5px;

  @media screen and (max-width: 767px) {
    font-size: 1em;
  }
`;

export const Cause = styled.div`
  font-size: 0.75em;
  text-transform: uppercase;
`;
