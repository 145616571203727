import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import * as UI from "./ui";

const FADE_DURATION_MS = 500;

const Slide = ({ slide, height, width, aspectRatio, visible, onNext, onPrevious, disableControls, disableCaptions }) => {
  const dimensions = {
    aspectRatio: aspectRatio.join(":"),
  };

  if(height && width) {
    if((width / height) <= (aspectRatio[0] / aspectRatio[1])) {
      // Width is the limiting dimension.
      dimensions.width = Math.floor(width);
    } else {
      // Height is the limiting dimension.
      dimensions.height = Math.floor(height);
    }
  } else {
    if(width) {
      // Only have width, so set that.
      dimensions.width = Math.floor(width);
    }
    if(height) {
      // Only have height, so set that.
      dimensions.height = Math.floor(height);
    }
  }

  return (
    <UI.Slide visible={visible} fadeDuration={FADE_DURATION_MS}>
      <UI.SlideImage
        source={slide.image}
        {...dimensions}
        fetchFormat="auto"
        quality="auto:best"
        dpr={window.devicePixelRatio}
      />
      <UI.Footer disabled={disableControls && disableCaptions}>
        <UI.Control disabled={disableControls} onClick={onPrevious}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </UI.Control>

        <UI.Caption disabled={disableCaptions}>{slide.caption}</UI.Caption>

        <UI.Control disabled={disableControls} onClick={onNext}>
          <FontAwesomeIcon icon={faAngleRight} />
        </UI.Control>
      </UI.Footer>
    </UI.Slide>
  );
}

export default Slide;
