import styled from "styled-components";
import { Link } from "react-router-dom";

export const SuperFooter = styled.div`
  width: 100%;
  background-color: #f9f9f9;
  padding: 20px 0 0;
`;

export const Menu = styled.ul`
  display: flex;
  margin: 0 -5px;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const MenuItem = styled.li`
  width: 25%;
  padding: 0 5px 10px;
  flex: 1;

  @media screen and (max-width: 768px) {
    min-width: 100%;
  }

  a {
    border-radius: 2px;
    background-color: #dddddd;
    color: #000000;
    display: block;
    font-size: 0.875em;
    font-weight: 600;
    height: 100%;
    line-height: 1.6em;
    padding: 20px;

    &:hover {
      background-color: #cccccc;
    }

    @media screen and (max-width: 768px) {
      padding: 10px;
    }
  }
`;

export const Contact = styled.div`
  color: #111111;
  margin-bottom: 20px;
`;

export const Legal = styled.div`
  font-weight: bold;
`;

export const TicketRequest = styled(Link)`
  cursor: pointer;
  font: inherit;
  border: none;
  background: none;
  color: #0000ee;
`;
