import React, { useContext } from "react";

import { ToolContext, DONOR_INFO_STEP, QR_CODE_STEP } from "./ToolProvider";
import Switch from "./Switch";
import Page from "./Page";
import InputStep from "./InputStep";
import QrCodeStep from "./QrCodeStep";

const Tool = ({ sweepstakes, nonprofit }) => {
  const { setStep } = useContext(ToolContext);

  return (
    <Page sweepstakes={sweepstakes} nonprofit={nonprofit}>
      <Switch>
        <InputStep
          steps={DONOR_INFO_STEP}
          sweepstakes={sweepstakes}
          onComplete={() => setStep(QR_CODE_STEP)}
        />
        <QrCodeStep
          steps={QR_CODE_STEP}
          sweepstakes={sweepstakes}
          nonprofit={nonprofit}
        />
      </Switch>
    </Page>
  );
};

export default Tool;
