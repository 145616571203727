import React from "react";

import SweepstakesGoogleTag from "../SweepstakesGoogleTag";
import * as UI from "./ui";
import Header from '../Header';
import Footer from '../Footer';

const Page = ({ children }) => (
  <React.Fragment>
    <SweepstakesGoogleTag />
    <UI.Layout>
      <Header />
      <UI.Body>{children}</UI.Body>
      <Footer />
    </UI.Layout>
  </React.Fragment>
);

export default Page;
