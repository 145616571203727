import React from "react";

import ToolProvider from "./ToolProvider";
import Tool from "./Tool";

const DonationTool = (props) => {
  return (
    <ToolProvider bootstrap={props.bootstrap}>
      <Tool {...props} />
    </ToolProvider>
  );
}

export default DonationTool;
