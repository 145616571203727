import React from 'react';

import * as UI from "./ui";

// For some reason, supplying an onTouchEnd event
// prevents Mobile Safari from requiring two clicks.
const NOOP = () => {};

const ClosedContent = ({ sweepstakes }) => (
  <UI.ClosedLayout>
    <UI.ClosedTitle>Sweepstakes Closing</UI.ClosedTitle>
    <UI.Apology>We’re so sorry, but requests for entries without a donation have closed. Sweepstakes ends soon.</UI.Apology>
    <UI.Button onTouchEnd={NOOP} to="/">Go to Sweepstakes Home</UI.Button>
  </UI.ClosedLayout>
);

export default ClosedContent;
