import React from "react";
import { isNil, isEmpty, either } from "ramda";
import { Helmet } from 'react-helmet';

import { openGraphCloudinaryUrl } from "../../lib/cloudinary";

const isBlank = either(isNil, isEmpty);

const SweepstakesHeadTags = ({ title, sweepstakes }) => {
  const { headline } = sweepstakes;
  const pageTitle = title || headline;

  return (
    <Helmet>
      <meta property="og:type" content="website" />
      { pageTitle && <title>{pageTitle}</title> }
      { pageTitle && <meta property="og:title" content={pageTitle} /> }
      { !isBlank(sweepstakes.slides) && <meta property="og:image" content={openGraphCloudinaryUrl(sweepstakes.slides[0].image)} /> }
    </Helmet>
  );
}

export default SweepstakesHeadTags;
