import { gql } from "@apollo/client";

export const encryptDonationInfoMutation = gql`
  mutation EncryptDonationInfo($data: String!) {
    encryptData(data: $data) {
      error {
        code
        message
      }
      encryptedData
    }
  }
`;

