import React from "react";
import { Navigate } from "react-router-dom";

import SweepstakesHeadTags from "../SweepstakesHeadTags";
import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import { ScheduleSwitch } from "../../lib/schedule";
import WinnerSlideshow from "./WinnerSlideshow";
import WinnerPresentation from "./WinnerPresentation";
import { sweepstakesQuery } from "./queries";

const WinnerPage = () => {
  const { data, loading, error } = useSweepstakesQuery(sweepstakesQuery)

  if(loading || error) {
    return null;
  }

  const { sweepstakes } = data;

  return (
    <React.Fragment>
      <SweepstakesHeadTags sweepstakes={sweepstakes} />
      <ScheduleSwitch>
        <Navigate to="/" periods={["review"]} />
        <WinnerSlideshow periods={["open", "closing", "grace", "closed"]} />
        <WinnerPresentation periods={["drawn", "archived"]} />
      </ScheduleSwitch>
    </React.Fragment>
  );
}

export default WinnerPage;
