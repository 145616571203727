import styled, { keyframes } from "styled-components";

const shine = keyframes`
  0% {
  }
  100% {
    transform: translate(100%, 100%);
  }
`;

export const Background = styled.div`
  padding: 12px;
  margin-bottom: 12px;
  text-align: center;
  border-radius: 2px;
  line-height: 1.25em;
  font-weight: 700;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    padding: 12px;
    font-size: 0.875em;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    transform: translate(-100%, -100%);
    background-image: linear-gradient(to left top, transparent 10%, rgba(255,255,255,0.2) 50%, transparent 90%);
    animation: 750ms ${shine} ease-out forwards;
    animation-delay: 500ms;
  }
`;
