import React from 'react';
import { faFacebookF} from '@fortawesome/free-brands-svg-icons';

import * as UI from "./ui";

const opts = ['width=500', 'height=500'];

const Facebook = ({ url }) => {
  const onClick = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, 'facebook', opts);
  }

  return (
    <UI.Button onClick={onClick}>
      <UI.Icon icon={faFacebookF} />
    </UI.Button>
  )
}

export default Facebook;

