import React from "react";
import { gql } from "@apollo/client";

import useSweepstakesQuery from "../lib/useSweepstakesQuery";
import GoogleTagManager from "../lib/GoogleTagManager";

const googleTagQuery = gql`
  query GetGoogleTag($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      googleContainerId
    }
  }
`;

const SweepstakesGoogleTag = () => {
  const { data, loading, error } = useSweepstakesQuery(googleTagQuery);

  if(error || loading) {
    return null;
  }

  const { sweepstakes } = data;

  return <GoogleTagManager containerId={sweepstakes.googleContainerId} />
};

export default SweepstakesGoogleTag;
