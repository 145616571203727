import React, { useContext } from "react";

import { ToolContext } from "./ToolProvider";

const Switch = ({ enableDesktopUnfold, children }) => {
  const { mode, step } = useContext(ToolContext);

  // If the switch should show all in desktop mode, short-circuit and return all children.
  if(enableDesktopUnfold && mode === "desktop") {
    return children;
  }

  // If we're not unfolding, find the first child that has a matching steps prop
  // for the given step in context.
  let element = null;

  React.Children.forEach(children, child => {
    if (element === null && React.isValidElement(child)) {
      if(child.props.steps === step || (child.props.steps.includes && child.props.steps.includes(step))) {
        element = child;
      }
    }
  });

  return element;
}

export default Switch;

