import styled from "styled-components";

export const Header = styled.header`
  border-bottom: 1px solid #333;
  margin: 20px 0;
  padding-bottom: 20px;
`

export const Title = styled.h2`
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 10px;

  @media screen and (max-width: 1024px) {
    font-size: 1.5em;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.25em;
  }
`

export const Document = styled.div`
  font-size: 0.875em;
`;
