import { gql } from "@apollo/client";

export const getPromoCodeQuery = gql`
  query GetPromoCode($name: String!, $id: SweepstakesSlug!) {
    promoCode(name: $name, id: $id) {
      id
      name
      bonusPercent
    }
  }
`;
