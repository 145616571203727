import React, { useEffect } from "react";
import confetti from "canvas-confetti";

import * as UI from "./ui";

export const launch = ({ delay }) => {
  setTimeout(() => {
    confetti({
      angle: 90,
      gravity: 0.4,
      origin: { x: 0.5, y: 1 },
      particleCount: 100,
      spread: 90,
      scalar: 2,
      startVelocity: 80,
    });
  }, delay);
}

const TimeToDrawScene = ({ length, sweepstakes }) => {
  useEffect(() => { 
    launch({ delay: Math.floor(length * 1000 * 0.09) })
  }, [ length ]);

  return (
    <UI.Layout>
      <UI.TimeToDrawText>
        <UI.Notice length={length}>
          Time to Draw the Winning Entry!
        </UI.Notice>
        <UI.PrizeName length={length}>
          {sweepstakes.prize.name}
        </UI.PrizeName>
      </UI.TimeToDrawText>
      <UI.Disclaimer>
        This is a presentation of the secure, random number drawing of the winner conducted at the close of this sweepstakes.
      </UI.Disclaimer>
    </UI.Layout>
  );
};

export default TimeToDrawScene;
