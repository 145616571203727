import { useCallback, useContext, useEffect, useRef } from "react";
import { UNSAFE_NavigationContext } from 'react-router-dom'

const useBlocker = (blocker, shouldPrompt) => {
  // Handle react-router navigations
  const navigator = useContext(UNSAFE_NavigationContext).navigator

  useEffect(() => {
    if (!shouldPrompt) return

    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock()
          tx.retry()
        },
      }

      blocker(autoUnblockingTx)
    })

    return unblock
  }, [navigator, blocker, shouldPrompt])

  // Handle navigations away from the site
  const hasWorkListener = useRef(false);

  useEffect(() => {
    if(shouldPrompt && !hasWorkListener.current) {
      window.onbeforeunload = () => true;
      hasWorkListener.current = true;
    }

    return () => {
      if (hasWorkListener.current) {
        window.onbeforeunload = null
        hasWorkListener.current = false;
      };
    }
  }, [shouldPrompt]);
};

const usePrompt = (message, shouldPrompt) => {
  const blocker = useCallback(tx => {
    if (window.confirm(message)) {
      tx.retry();
    }
  }, [message]);

  useBlocker(blocker, shouldPrompt);

};

export default usePrompt;
