import React from "react";

import DrawnNotice from '../DrawnNotice';
import CurrentSweepstakesCTA from '../CurrentSweepstakesCTA';

const EndedNotice = ({ sweepstakes, isArchived }) => {
  return (
    <React.Fragment>
      {isArchived && <CurrentSweepstakesCTA nonprofitId={sweepstakes.nonprofit.id} />}
      <DrawnNotice sweepstakes={sweepstakes} isArchived={isArchived} />
    </React.Fragment>
  )
};

export default EndedNotice;
