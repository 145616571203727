import { useEffect } from 'react';

const FacebookPixel = ({ sweepstakes: { facebookPixelId }}) => {
  useEffect(() => {
    if(facebookPixelId) {
      window.fbq('init', facebookPixelId);
      window.fbq('track', 'PageView');
    }
  }, [facebookPixelId]);

  return null;
}

export default FacebookPixel;
