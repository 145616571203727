import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Layout = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0 10px;
`;

export const WidgetTitle = styled.div`
  min-width: 100%;
  font-weight: 300;
  font-size: 0.625em;
  line-height: 1.6em;
  text-transform: uppercase;
  margin-bottom: 2px;
`;

export const Icon = styled(FontAwesomeIcon)`
  height: 15px;
  width: 15px;
  color: #888888;
  cursor: pointer;
  transition-property: color;
  transition-duration: 0.4s;

  &:hover {
    color: #666666;
  }
`;

export const Button = styled.div`
  background-color: #e3e3e3;
  padding: 5px;
  border-radius: 3px;
`;
