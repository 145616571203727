import { Image as CloudinaryImage } from "cloudinary-react";
import { reject, isNil, mergeLeft } from "ramda";

const Image = ({ source, ...rest }) => {
  if(!source) {
    return null;
  }

  const props = mergeLeft(reject(isNil, source), rest);
  return <CloudinaryImage {...props} />
};

export default Image;
