import { gql } from "@apollo/client";

export const fulfillTicketRequestMutation = gql`
  mutation FulfillTicketRequest($token: String!, $attributes: FulfillTicketRequestInput!) {
    fulfillTicketRequest(token: $token, attributes: $attributes) {
      error {
        code
        message
      }
    }
  }
`;

