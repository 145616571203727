import React, { useState } from "react";
import { useWindowWidth } from '@react-hook/window-size/throttled'

export const MODE_BREAKPOINT = 768;

export const DONOR_INFO_STEP = 0;
export const RECEIPT_STEP = 1;

export const ToolContext = React.createContext();

const STEP_NAMES_FOR_EVENT = [];

STEP_NAMES_FOR_EVENT[DONOR_INFO_STEP] = "Entry Request Input";
STEP_NAMES_FOR_EVENT[RECEIPT_STEP] = "Entry Request Receipt";

const ToolProvider = ({ children, expired, token, alreadyFulfilled }) => {
  const width = useWindowWidth();
  const mode = (width < MODE_BREAKPOINT) ? "mobile" : "desktop";

  const [ donor, setDonor ] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "US",
    },
  });

  const initialStep = alreadyFulfilled ? RECEIPT_STEP : DONOR_INFO_STEP;

  const [ step, setStepValue ] = useState(initialStep);

  const setStep = nextStep => {
    setStepValue(nextStep);
  };

  const contextValue = {
    mode,
    step,
    setStep,
    donor,
    setDonor,
    token,
    alreadyFulfilled,
    expired,
  };

  return (
    <ToolContext.Provider value={contextValue}>
      {children}
    </ToolContext.Provider>
  )
}

export default ToolProvider;

