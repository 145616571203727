import React from "react";
import { propOr } from "ramda";

import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import { sweepstakesAnalyticsQuery } from "./gql";
import FacebookPixel from "./FacebookPixel";
import GTag from "./GTag";

const AnalyticsTags = () => {
  const { loading, data } = useSweepstakesQuery(sweepstakesAnalyticsQuery)

  if(loading) {
    return null;
  }

  const sweepstakes = propOr({}, 'sweepstakes', data);

  return (
    <React.Fragment>
      <GTag sweepstakes={sweepstakes} />
      <FacebookPixel sweepstakes={sweepstakes} />
    </React.Fragment>
  );
};

export default AnalyticsTags;
