import { gql } from "@apollo/client";

export const currentSweepstakesQuery = gql`
  query CurrentSweepstakesQuery($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      nonprofit {
        id
        currentSweepstakes {
          id
          donationAppUrl
          slides {
            id
            image {
              publicId
              version
              cloudName
              crop
              gravity
              background
            }
          }
        }
      }
    }
  }
`;
