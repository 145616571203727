import styled, { keyframes } from "styled-components";

export const Layout = styled.div`
  flex: 1;
  position: relative;
`;

export const Card = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px;
`;

const slideDown = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
`;

const slideUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
`;

export const Heading = styled.div`
  opacity: 0;
  font-size: calc(12px + 4 * ((100vw - 320px) / 680));
  color: #ffffff;
  animation: 1s ${slideDown} ease-out forwards;
  padding: 12px;
  text-align: center;
`;

export const WinningNumber = styled.div`
  opacity: 0;
  font-size: calc(48px + 36 * ((100vw - 320px) / 680));
  color: #ffffff;
  animation: 1s ${slideDown} ease-out forwards;
  padding: 12px;
  text-align: center;
`;

export const WinnerInfo = styled.div`
  opacity: 0;
  animation: 1s ${slideUp} ease-out forwards;
  font-size: calc(16px + 24 * ((100vw - 320px) / 680));
  text-align: center;
  padding: 24px;
  color: rgba(255,255,255,0.75);
`;

export const Name = styled.div`
  color: #ffffff;
`;

export const Location = styled.div`
  font-size: 0.5em;
  padding: 12px 0;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  95% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const Replay = styled.button`
  animation: 5s ${fadeIn} ease-out forwards;
  background: #333;
  border: none;
  border-radius: 6px;
  bottom: 24px;
  color: #fff;
  font-size: 0.875em;
  font-weight: 600;
  left: 50%;
  padding: 12px 24px;
  position: absolute;
  transform: translateX(-50%);
`;
