import React from "react";

import { pickTextColor } from "../../lib/color";
import Layout from "../Layout";
import * as UI from "./ui";

const BACKGROUND_SPECIAL_CASES = {
  "#fff": "#000",
  "#ffffff": "#000",
};

const AnnouncementBar = ({ sweepstakes }) => {
  if(!sweepstakes.announcement && !sweepstakes.activeFlashContest) {
    return null;
  }

  const announcement = sweepstakes.activeFlashContest?.announcement || sweepstakes.announcement;

  const npoBackgroundColor = BACKGROUND_SPECIAL_CASES[sweepstakes.nonprofit.profile.color.toLowerCase()] || sweepstakes.nonprofit.profile.color;
  const backgroundColor = sweepstakes.activeFlashContest ? "#ff5822" : npoBackgroundColor;
  const color = sweepstakes.activeFlashContest ? "#ffffff" : pickTextColor({ background: backgroundColor, light: '#fff', dark: '#000' });

  return (
    <Layout.FullWidth fold>
      <Layout.FullWidthColumn spaced>
        <UI.Background style={{ backgroundColor, color }}>
          {announcement}
        </UI.Background>
      </Layout.FullWidthColumn>
    </Layout.FullWidth>
  );
}

export default AnnouncementBar;
