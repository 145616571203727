import React from 'react';
import numeral from 'numeral';

const CENTS_PER_DOLLAR = 100;
const DEFAULT_FORMAT = '$0,0';

export const toMoneyString = ({ cents, format = DEFAULT_FORMAT }) => numeral(cents / CENTS_PER_DOLLAR).format(format);

const Money = ({ cents, format = DEFAULT_FORMAT }) => (
  <span>
    {toMoneyString({ cents, format })}
  </span>
);

export default Money;

