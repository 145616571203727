import React from "react";

import Layout from "../Layout";
import * as UI from "./ui";

const SponsorSection = ({ sweepstakes }) => {
  if(sweepstakes.sponsors.length === 0) {
    return null;
  };

  return (
    <React.Fragment>
      <Layout.Divider />
      <Layout.FullWidth fold>
        <Layout.FullWidthColumn spaced>
          <UI.Heading>Thank you to our Partners & Sponsors</UI.Heading>
        </Layout.FullWidthColumn>
        {sweepstakes.sponsors.map((sponsor, index) => (
          <Layout.FixedColumn span={4} spaced key={sponsor.id} breakpoint="belowTablet" breakwidth="33.33%">
            <UI.Sponsor href={sponsor.website} target="_blank" rel="noopener noreferrer">
              <UI.Logo {...sponsor.logo} width="180" aspectRatio="5:3" crop="pad" />
            </UI.Sponsor>
          </Layout.FixedColumn>
        ))}
      </Layout.FullWidth>
    </React.Fragment>
  );
};

export default SponsorSection;
