import React from 'react';
import { useNavigate } from 'react-router-dom';

import * as UI from "./ui";
import { ScheduleGuard } from "../../lib/schedule";
import DonationLevel from '../DonationLevel';

const DonationLevels = ({ donationLevels, promoCode }) => {
  const navigate = useNavigate();
  const onQuickSelect = donationLevel => {
    navigate(`/donate?level=${donationLevel.level}`);
  }

  return (
    <UI.Layout>
      { donationLevels.map((donationLevel, index) => (
        <UI.Item key={index}>
          <ScheduleGuard enabledPeriods={["open", "closing"]}>
            <DonationLevel
              donationLevel={donationLevel}
              onClick={onQuickSelect}
              promoCode={promoCode}
            />
          </ScheduleGuard>
        </UI.Item>
      ))}
    </UI.Layout>
  );
}

export default DonationLevels;
