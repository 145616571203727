import styled, { keyframes } from "styled-components";

export const Layout = styled.div`
  flex: 1;
`;

const intro = keyframes`
  0% {
    opacity: 0;
  }

  3% {
    opacity: 1;
  }

  97% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export const NumberWheelLayout = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 60%;
  animation: ${intro} ease-in-out forwards;
  animation-duration: ${props => props.duration}ms;
  transform: translate(-50%, -50%);
`

export const Heading = styled.div`
  color: #ffffff;
  font-size: calc(16px + 16 * ((100vw - 320px) / 680));
  padding: 24px 0;
  white-space: nowrap;
`;

export const NumberWheelsBackground = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  border: 6px solid #ddd;
`;

export const NumberWheels = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
`;

export const Slot = styled.div`
  padding: 24px;
  height: 100%;

  ${props => props.small && `
    padding: 12px;
  `}

  &:not(:first-child) {
    border-right: 1px solid #ddd;
  }
`;
