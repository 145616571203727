import React from "react";
import { useParams } from "react-router-dom";

import SweepstakesHeadTags from "../SweepstakesHeadTags";
import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import GracePeriodGuard from "../GracePeriodGuard";
import { fulfillmentToolQuery } from "./queries";
import FulfillmentTool from "../FulfillmentTool";

const FulfillmentPage = () => {
  const { token } = useParams();

  const { data, loading, error } = useSweepstakesQuery(fulfillmentToolQuery, {
    variables: {
      token,
    }
  });

  if(loading || error) {
    return null;
  }

  const {
    sweepstakes,
    sweepstakes: {
      nonprofit
    },
    ticketRequest,
  } = data;

  return (
    <React.Fragment>
      <SweepstakesHeadTags sweepstakes={sweepstakes} title={`Redeem Entry Request | ${sweepstakes.headline}`} />
      <GracePeriodGuard schedule={sweepstakes.schedule}>
        <FulfillmentTool
          sweepstakes={sweepstakes}
          nonprofit={nonprofit}
          token={token}
          alreadyFulfilled={ticketRequest.isFulfilled}
        />
      </GracePeriodGuard>
    </React.Fragment>
  );
};

export default FulfillmentPage;
