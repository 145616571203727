import styled from "styled-components";
import Image from "../Image";

export const Layout = styled.div`
  position: relative;
  background-color: #000000;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  width: 100%;
  padding: 10px;
  color: #ffffff;
`;

export const Logo = styled(Image)`
  max-height: 75px;
  max-width: 160px;
`

export const Name = styled.div`
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 5px;

  @media screen and (max-width: 767px) {
    font-size: 1em;
  }
`;

export const Cause = styled.div`
  font-size: 0.75em;
  text-transform: uppercase;
`;

export const SlideshowLayout = styled.div`
  flex: 1 1 0;
  overflow: hidden;
`;
