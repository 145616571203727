import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";

import { receiptQuery } from "./gql";
import * as UI from "./ui";

const TIMEOUT = 10000;
const INTERVAL = 500;

const EntryNumbers = ({ donationId }) => {
  const [ timedOut, setTimedOut ] = useState(false);
  const { data, loading, error, stopPolling } = useQuery(receiptQuery, {
    pollInterval: INTERVAL,
    variables: { id: donationId },
  });

  const ticketRanges = useMemo(() => data?.receipt?.ticketRanges || [], [data]);

  useEffect(() => {
    if(ticketRanges.length) {
      stopPolling();
    }
  }, [ticketRanges, stopPolling]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimedOut(true);
      stopPolling();
    }, TIMEOUT);

    return () => clearTimeout(timeout);
  }, [stopPolling]);

  if(loading || error) return null;

  if(ticketRanges.length === 0) {
    if(timedOut) {
      return (
        <UI.LoadingEntryNumbers>
          Thanks for your patience. Processing your donation is taking longer than expected. Don’t worry, you’ll receive an email with your entry numbers.
          For more help, please contact us: <a href="mailto:support@tapkat.com">support@tapkat.com</a>
        </UI.LoadingEntryNumbers>
      )
    }

    return (
      <UI.LoadingEntryNumbers>
        Thank you for donating. Just a sec while we process your donation...
      </UI.LoadingEntryNumbers>
    );
  }

  return (
    <React.Fragment>
      <UI.EntrySummary>
        {data.receipt.rewardTicketCount} Entries + {data.receipt.promoCodeTicketCount} Promo Code Entries
      </UI.EntrySummary>
      <UI.EntryNumbers>
        <ul>
          { ticketRanges.map(({ min, max }) => (
            <li key={min}>Entry #{ min } - #{ max }</li>
          )) }
        </ul>
      </UI.EntryNumbers>
    </React.Fragment>
  );
};

export default EntryNumbers;
