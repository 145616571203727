import React from 'react';

import * as UI from "./ui";

// For some reason, supplying an onTouchEnd event
// prevents Mobile Safari from requiring two clicks.
const NOOP = () => {};

const ClosedContent = ({ sweepstakes }) => (
    <UI.Layout>
      <UI.Title>Sweepstakes has closed</UI.Title>
      <UI.Apology>We’re so sorry, but the time to enter this sweepstakes has run out.</UI.Apology>
      <UI.Button onTouchEnd={NOOP} to="/">Go to Sweepstakes Home</UI.Button>
    </UI.Layout>
  );

export default ClosedContent;
