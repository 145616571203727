import styled from 'styled-components';

import tapkatLogo from "../../assets/images/tapkat-logo.png";

export const Footer = styled.footer`
  ${props => !props.discrete && `
    background-color: #67778d;
  `}
  padding: 10px 0;

  @media print {
    display: none;
  }
`;

export const PoweredBy = styled.a`
  display: block;
`;

export const Logo = styled.img.attrs({
  src: tapkatLogo,
})`
  height: 30px;
`;

export const Copyright = styled.div`
  font-size: 0.75em;
  color: #dddddd;
  text-align: right;

  @media screen and (max-width: 479px) {
    text-align: left;
    font-size: 0.5em;
  }
`

export const Nav = styled.nav`
  font-size: 0.75em;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  a {
    color: #dddddd;
    text-decoration: underline;
  }

  @media screen and (max-width: 479px) {
    justify-content: flex-start;
    font-size: 0.5em;
  }
`;
