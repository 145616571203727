import styled from "styled-components";
import Image from "../Image";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
  font-size: 1.25em;
`;

export const Thumbnail = styled(Image)`
  width: 100%;
  margin-bottom: 12px;
`;

export const Label = styled.div`
  margin-bottom: 10px;
`;

export const Button = styled.div`
  background-color: #00C19D;
  color: white;
  border: none;
  border-radius: 2px;
  display: block;
  font-weight: 700;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;
