import React from "react";

import * as UI from "./ui";

const DonorCard = ({ donor }) => (
  <UI.Layout>
    <UI.Name>{donor.firstName} {donor.lastName}</UI.Name>
    <UI.Address>
      <p>{donor.addressLine1} {donor.addressLine2}</p>
      <p>{donor.city}, {donor.state}, {donor.zip} {donor.country}</p>
    </UI.Address>
    <UI.Contact>
      <p>{donor.email}</p>
      <p>{donor.phoneNumber}</p>
    </UI.Contact>
  </UI.Layout>
);

export default DonorCard;
