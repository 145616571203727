/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { faClock } from "@fortawesome/free-solid-svg-icons";

import usePrompt from "../../lib/usePrompt";
import * as UI from "./ui";

// https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout#Notes
// Set a safe limit of about 28 days to avoid overflowing setTimeout and executing immediately.
const SAFE_LIMIT = 24 * 24 * 60 * 60 * 1000;

const GRACE_WARNING = "Are you sure you want to leave this page? Sweepstakes has closed and you will not be able to complete your entry.";

const CountdownTimer = ({ period, onEnd }) => {
  // Re-usable timer ID to allow for cancelling on dismount.
  let timerId;

  const beginsAt = DateTime.fromISO(period[0]);
  const endsAt = DateTime.fromISO(period[1]);

  // By default, we do not yet know if the timer should be started.
  const [ enableTimer, setEnableTimer ] = useState(false);

  const enableTick = () => {
    // Clamp next enable tick to SAFE_LIMIT.
    const timeUntilCountdown = Math.min(beginsAt.diffNow().milliseconds, SAFE_LIMIT)

    if(timeUntilCountdown > 0) {
      timerId = setTimeout(enableTick, timeUntilCountdown)
      return () => clearTimeout(timerId);
    } else {
      setEnableTimer(true);
    }
  }

  // Setup tick to enable timer.
  useEffect(enableTick, []);

  // Calculate timeLeft as a Duration, to be updated in timerTick
  const [ timeLeft, setTimeLeft ] = useState(endsAt.diffNow());

  const timerTick = () => {
    const timeLeft = endsAt.diffNow();

    if(timeLeft.milliseconds > 0) {
      setTimeLeft(timeLeft)
      timerId = setTimeout(timerTick, 1000);
      return () => clearTimeout(timerId);
    } else {
      onEnd();
    }
  }

  // Setup actual timer.
  useEffect(() => {
    if(enableTimer) {
      return timerTick()
    }
  }, [ enableTimer ]);

  usePrompt(GRACE_WARNING, enableTimer);

  if(timeLeft < 0) {
    return null;
  }

  return (
    <UI.CountdownTimerLayout enabled={enableTimer}>
      <UI.Icon icon={faClock} /> {timeLeft.toFormat("mm:ss")} left to enter
    </UI.CountdownTimerLayout>
  );
};

export default CountdownTimer;
