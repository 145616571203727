import React, { useContext } from "react";

import { ToolContext } from "./ToolProvider";
import Layout from "../Layout";
import Field from "./Field";
import Select from "./Select";
import * as UI from "./ui";
import countries from "../../lib/countries";

const DonorForm = ({ disabled, onComplete }) => {
  const { donor, setDonor } = useContext(ToolContext);

  const onSubmit = e => {
    e.preventDefault();
    onComplete();
  };

  const setDonorField = id => e => {
    const value = e.target.value;

    setDonor(current => ({
      ...current,
      [id]: value,
    }));
  };

  return (
    <Layout.FullWidthColumn>
      <UI.SubstepHeading>Enter Your Information</UI.SubstepHeading>
      <UI.Form onSubmit={onSubmit}>
        <UI.FieldGroup>
          <Field id="firstName" label="First Name" onChange={setDonorField("firstName")} value={donor.firstName} required />
          <Field id="lastName" label="Last Name" onChange={setDonorField("lastName")} value={donor.lastName} required />
        </UI.FieldGroup>
        <Field type="email" id="email" label="Email" onChange={setDonorField("email")} value={donor.email} required />
        <Field type="tel" id="phoneNumber" label="Phone" onChange={setDonorField("phoneNumber")} value={donor.phoneNumber} required />
        <UI.FieldGroup>
          <Field id="addressLine1" label="Address" onChange={setDonorField("addressLine1")} value={donor.addressLine1} weight={3} required />
          <Field id="addressLine2" label="Apt #" onChange={setDonorField("addressLine2")} value={donor.addressLine2} weight={2} />
        </UI.FieldGroup>
        <UI.FieldGroup>
          <Field id="city" label="City" onChange={setDonorField("city")} value={donor.city} weight={2} required />
          <Field id="state" label="State" onChange={setDonorField("state")} value={donor.state} required />
          <Field id="zipCode" label="Zip" onChange={setDonorField("zipCode")} value={donor.zipCode} required />
        </UI.FieldGroup>
        <Select id="country" label="Country" onChange={setDonorField("country")} value={donor.country} options={countries} required />
        <UI.Legal>* By continuing, I certify I am 18 years of age or older, eligible to participate in this sweepstakes, and I agree to abide by the sweepstakes rules.</UI.Legal>
        <UI.FieldGroup>
          <UI.ActionButton value="Continue" disabled={disabled} />
        </UI.FieldGroup>
      </UI.Form>
    </Layout.FullWidthColumn>
  );
};

export default DonorForm;
