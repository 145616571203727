import React, { useState, useEffect, useRef } from 'react';

import Slide from "./Slide";
import * as UI from "./ui";

const INTERVAL_MS = 7000;

const Slideshow = ({ slides, height, width, aspectRatio, disableControls, disableCaptions }) => {
  const [ currentIndex, setCurrentIndex ] = useState(0);
  const timer = useRef(null);

  const setupTimer = () => {
    const tick = () => {
      setCurrentIndex(lastIndex => (lastIndex + 1) % slides.length);
    };

    timer.current = setInterval(tick, INTERVAL_MS);
    return () => { clearTimeout(timer.current) }
  };

  const resetTimer = () => {
    clearTimeout(timer.current);
    // Wait a full interval before we start the clock ticking again.
    // Essentially doubles the interval for a manually accessed slide.
    timer.current = setTimeout(setupTimer, INTERVAL_MS);
  };

  useEffect(setupTimer, [ slides ]);

  if(slides.length === 0) {
    return null;
  }

  const handlePrevious = fromIndex => {
    resetTimer();

    if(fromIndex - 1 < 0) {
      return setCurrentIndex(slides.length - 1);
    }

    return setCurrentIndex(fromIndex - 1);
  };

  const handleNext = fromIndex => {
    resetTimer();

    return setCurrentIndex((fromIndex + 1) % slides.length);
  };

  return (
    <UI.Layout>
      <UI.SlideGroup>
        { slides.map((slide, index) => (
          <Slide
            key={slide.__typename + slide.id}
            slide={slide}
            visible={index === currentIndex}
            height={height}
            width={width}
            aspectRatio={aspectRatio}
            disableCaptions={disableCaptions}
            disableControls={slides.length === 1 || disableControls}
            onPrevious={() => handlePrevious(index)}
            onNext={() => handleNext(index)}
          />
        ))}
      </UI.SlideGroup>
    </UI.Layout>
  );
}

export default Slideshow;
