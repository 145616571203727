import React, { useContext } from 'react';
import { isEmpty } from "ramda";

import { BootParameterContext } from "../../lib/BootParameterProvider";
import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import PlaceholderLevels from "./PlaceholderLevels";
import DonationLevels from "./DonationLevels";
import { donationLevelQuery } from "./queries";
import * as UI from "./ui";

const QuickSelect = () => {
  const { promo } = useContext(BootParameterContext);

  const { data, loading, error } = useSweepstakesQuery(donationLevelQuery, {
    variables: {
      promoCodeName: promo,
    },
  });

  if(error || loading) {
    return null;
  }

  const {
    sweepstakes: { donationLevels },
    promoCode
  } = data;

  return (
    <div>
      <UI.Title>Select Your Donation Amount</UI.Title>
      { isEmpty(donationLevels) ? (
        <PlaceholderLevels />
      ) : (
        <DonationLevels donationLevels={donationLevels} promoCode={promoCode} />
      )}
    </div>
  );
}

export default QuickSelect;
