import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { isNil, isEmpty, either } from "ramda";

const isBlank = either(isNil, isEmpty);

// https://developers.google.com/tag-platform/gtagjs/install#create_your_own_tag
const GTag = ({ sweepstakes }) => {
  const googleAdsConversionId = sweepstakes.googleAdsConversionId ?
    sweepstakes.googleAdsConversionId.split("/")[0] : null;

  const ids = [
    // TapKat Platform Google Analytics
    process.env.REACT_APP_GOOGLE_ANALYTICS_PROPERTY_ID,

    // Sweepstakes-specific Google Analytics
    sweepstakes.googleAnalyticsPropertyId,

    // Sweepstakes-specific Google Ads ID
    googleAdsConversionId,
  ].filter(id => !isBlank(id));

  useEffect(() => {
    // Add a config event to the dataLayer for each ID we want to setup.
    // NOTE: This assumes that the public/index.html file includes the basic
    // setup code that creates window.dataLayer and defines window.gtag().
    ids.forEach(id => {
      window.gtag('config', id)
    });
  }, [ids]);

  return (
    <Helmet>
      {ids.map(id => (
        <script key={id} async src={`https://www.googletagmanager.com/gtag/js?id=${id}`}></script>
      ))}
    </Helmet>
  );
};

export default GTag;
