import { gql } from "@apollo/client";

export const headerQuery = gql`
  query SubheaderQuery($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      threshold
      totalDonations

      nonprofit {
        id
        name

        profile {
          id
          cause
          color

          logo {
            publicId
            version
            cloudName
          }
        }
      }
    }
  }
`

