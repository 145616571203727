import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { path, isNil } from "ramda";
import useDimensions from "react-use-dimensions";

import Layout from "../Layout";
import Footer from '../Footer';
import Slideshow from "../Slideshow";
import Presentation, { Scene } from "../../lib/Presentation";
import { presentationQuery } from "./queries";
import TimeToDrawScene from "./TimeToDrawScene";
import NumberWheelScene from "./NumberWheelScene";
import WinnerSnapshotScene from "./WinnerSnapshotScene";
import useSweepstakesQuery from "../../lib/useSweepstakesQuery";

import * as UI from "./ui";

const POLL_INTERVAL_MS = 15 * 1000;

const WinnerPresentation = () => {
  const [slideshowRef, { height, width }] = useDimensions();
  const [ playCount, setPlayCount ] = useState(0);

  const { data, loading, stopPolling } = useSweepstakesQuery(presentationQuery, {
    pollInterval: POLL_INTERVAL_MS,
  });

  const winnerSnapshot = path(['sweepstakes', 'prize', 'winnerSnapshot'], data);

  useEffect(() => {
    if(!isNil(winnerSnapshot)) {
      stopPolling();
    }
  }, [stopPolling, winnerSnapshot])

  if(loading) { 
    return null;
  }
  const {
    sweepstakes,
    sweepstakes: {
      nonprofit,
    }
  } = data;

  const styles = {
    backgroundColor: nonprofit.profile.color,
  };

  return (
    <UI.Layout>
      <UI.Header style={styles}>
        <Layout.FullWidth alignItems="center">
          <Layout.FixedColumn span={3} breakpoint="belowTablet" breakwidth="auto">
            <Link to="/">
              <UI.Logo source={nonprofit.profile.logo} alt={nonprofit.name} crop="fit" height="75" width="160" dpr="3.0" quality="auto:best" />
            </Link>
          </Layout.FixedColumn>
          <Layout.FlexibleColumn span={[9,17]} breakpoint="belowTablet" breakwidth="auto">
            <UI.Name>{nonprofit.name}</UI.Name>
            <UI.Cause>{nonprofit.profile.cause}</UI.Cause>
          </Layout.FlexibleColumn>
        </Layout.FullWidth>
      </UI.Header>
      { winnerSnapshot ? (
        <Presentation length={25} key={playCount}>
          <Scene from={0} to={10}>
            <TimeToDrawScene sweepstakes={sweepstakes} />
          </Scene>
          <Scene from={10} to={25}>
            <NumberWheelScene sweepstakes={sweepstakes} />
          </Scene>
          <Scene from={25} to={26}>
            <WinnerSnapshotScene sweepstakes={sweepstakes} onReplay={() => setPlayCount(count => count + 1)} />
          </Scene>
        </Presentation>
      ) : (
        <UI.SlideshowLayout ref={slideshowRef}>
          <Slideshow
            slides={sweepstakes.slides}
            disableCaptions
            disableControls
            height={height}
            width={width}
            aspectRatio={[16,9]}
          />
        </UI.SlideshowLayout>
      )}
      <Footer discrete />
    </UI.Layout>
  )
};

export default WinnerPresentation;
