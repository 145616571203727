import styled from "styled-components";
import { Link } from "react-router-dom";

export const Header = styled.header`
  margin: 40px 0;
`;

export const Heading = styled.div`
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 5px;

  @media screen and (min-width: 768px) {
    font-size: 2em;
  }
`;

export const Subheading = styled.div`
  margin-bottom: 5px;
`;

export const Notice = styled.div`
  font-size: 0.75em;
  margin-bottom: 40px;
`

export const Options = styled.div`
`;

export const OptionButton = styled.button`
  appearance: none;
  background-color: #00C19D;
  border: none;
  border-radius: 2px;
  color: #fff;
  display: block;
  font-size: 1em;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 40px;
  margin: 0 5px 40px;
  width: 300px;

  &:hover {
    background-color: #00755f;
  }
`

export const ClosedLayout = styled.div`
  min-width: 335px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #eeeeee;
  padding: 40px;
`;

export const ClosedTitle = styled.h2`
  max-width: 1200px;
  margin: 20px auto;
  font-size: 2em;
  font-weight: 700;

  @media screen and (max-width: 1024px) {
    margin: 30px auto;
    font-size: 1.5em;
  }

  @media screen and (max-width: 768px) {
    margin: 20px auto;
    font-size: 1.25em;
  }
`;

export const Apology = styled.p`
  max-width: 1200px;
  margin: 20px auto 40px;
  font-size: 0.875em;
`;

export const Button = styled(Link)`
  background-color: #00C19D;
  border: none;
  border-radius: 2px;
  color: #fff;
  display: block;
  font-size: 1em;
  font-weight: 700;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  ${props => props.floating && `
    width: auto;
    font-size: 1.25em;
    padding: 20px;
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.45);
  `}
`;
