import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { path } from "ramda";
import HCaptcha from "@hcaptcha/react-hcaptcha";

import useFingerprint from "../../lib/useFingerprint";
import Layout from "../Layout";
import Receipt from "./Receipt";
import Form from "./Form";
import { createMailinTicketRequestMutation } from "./gql";
import * as UI from "./ui";

const MailinTicketRequestForm = ({ sweepstakes }) => {
  const [ captcha, setCaptcha ] = useState(null);
  const fingerprint = useFingerprint();
  const [ createMailinTicketRequest, { called, data, loading }] = useMutation(createMailinTicketRequestMutation);

  const error = called && path(['createMailinTicketRequest', 'error', 'message'], data);
  const mailinTicketRequest = path(['createMailinTicketRequest', 'mailinTicketRequest'], data);

  if(mailinTicketRequest) {
    return (
      <Layout.FullWidth fold>
        <Receipt mailinTicketRequest={mailinTicketRequest} sweepstakes={sweepstakes} />
      </Layout.FullWidth>
    );
  }

  const handleSubmit = attributes => {
    createMailinTicketRequest({
      variables: {
        sweepstakesId: sweepstakes.id,
        attributes: {
          ...attributes,
          fingerprint,
        },
        captcha,
      }
    })
  };

  return (
    <UI.Layout>
      <UI.Instructions>To enter into this sweepstakes without a donation, fill in the entire form.</UI.Instructions>
      <UI.Captcha>
        <HCaptcha
          sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
          onVerify={setCaptcha}
          onExpire={() => setCaptcha(null)}
        />
      </UI.Captcha>
      <Form onSubmit={handleSubmit} disabled={loading || !captcha} />
      <Layout.FullWidthColumn>
        { error && <UI.ErrorMessage>{error}</UI.ErrorMessage> }
      </Layout.FullWidthColumn>
    </UI.Layout>
  );
};

export default MailinTicketRequestForm;
