import React from 'react';
import { Navigate } from "react-router-dom";

import SweepstakesHeadTags from "../SweepstakesHeadTags";
import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import { useSchedulePeriod } from "../../lib/schedule";
import { ScheduleMask } from "../../lib/schedule";
import Page from "../Page";
import SuperFooter from "../SuperFooter";
import FloatingAction from "../../lib/FloatingAction";
import DonateNow from "../DonateNow";
import Layout from "../Layout";
import Item from "./Item";
import { faqQuery } from "./queries";
import * as UI from "./ui";

const FaqContent = () => {
  const period = useSchedulePeriod();
  const isArchived = period === "archived";
  const { data = {}, loading, error } = useSweepstakesQuery(faqQuery)

  if (isArchived) {
    return <Navigate to="/" replace />;
  }

  if(loading || error) {
    return null;
  }

  const { sweepstakes } = data;

  return (
    <Page>
      <SweepstakesHeadTags sweepstakes={sweepstakes} title="FAQ" />
      <Layout.FullWidth fold>
        <Layout.FlexibleColumn span={[1,20]}>
          <UI.Title>Frequently Asked Questions</UI.Title>
          { sweepstakes.faqs.map((item, i) => <Item key={i} item={item} />) }
        </Layout.FlexibleColumn>
      </Layout.FullWidth>
      <SuperFooter sweepstakes={sweepstakes} />
      <ScheduleMask enabledPeriods={["open", "closing"]}>
        <FloatingAction>
          <DonateNow floating />
        </FloatingAction>
      </ScheduleMask>
    </Page>
  );
}

export default FaqContent;
