import styled from "styled-components";

export const Layout = styled.ol`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 5px -5px;
`;

export const Item = styled.li`
  width: 50%;
  padding: 5px;

  @media screen and (min-width: 767px) and (max-width: 1023px) {
    width: 25%;
  }
`;

export const Title = styled.div`
  font-size: 1.125em;
  font-weight: 400;
  margin-bottom: 10px;
`;


export const PlaceholderLevel = styled.div`
  background-color: #e3e3e3;
  border: #ddd solid 1px;
  border-radius: 2px;
  padding: 15px;
  height: 70px;
`;

export const PlaceholderNotice = styled.div`
  color: #ffffff;
  border-radius: 2px;
  background-color: #00C19D;
  border-color: #00C19D;
  font-size: 0.75em;
  font-weight: 700;
  left: 50%;
  padding: 15px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
`;
