import styled, { keyframes } from "styled-components";

export const Layout = styled.div`
  position: relative;
  flex: 1;
`;

export const TimeToDrawText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px;
`;

const slideDownAndUp = keyframes`
  0% {
    transform: translateY(-100%);
  }
  10% {
    transform: translateY(0%);
    opacity: 1;
  }
  95% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(-25%);
    opacity: 0;
  }
`;

const slideUpAndDown = keyframes`
  0% {
    transform: translateY(100%);
  }
  2% {
    transform: translateY(100%);
    opacity: 0;
  }
  10% {
    transform: translateY(0%);
    opacity: 1;
  }
  95% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(25%);
    opacity: 0;
  }
`;

export const Notice = styled.div`
  opacity: 0;
  animation: ${props => props.length}s ${slideDownAndUp} ease-out forwards;
  font-size: calc(12px + 12 * ((100vw - 320px) / 680));
  padding: 12px;
  color: rgba(255,255,255,0.5);
  font-weight: 600;
`;

export const PrizeName = styled.div`
  opacity: 0;
  color: #ffffff;
  font-size: calc(20px + 24 * ((100vw - 320px) / 680));
  animation: ${props => props.length}s ${slideUpAndDown} ease-out forwards;
  padding: 12px;
  font-weight: 600;
`;

export const Disclaimer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #666666;
  text-align: center;
  font-size: 0.75em;
  padding: 10px;
`;
