import { useState, useEffect } from "react";
import Fingerprint2 from "fingerprintjs2"

const useFingerprint = (options = {}) => {
  const [ fingerprint, setFingerprint ] = useState(null);

  useEffect(() => {
    Fingerprint2.getPromise(options).then(components => {
      const values = components.map(component => component.value);
      setFingerprint(Fingerprint2.x64hash128(values.join(''), 31));
    })
  }, [options])

  return fingerprint;
}

export default useFingerprint;

