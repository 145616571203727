import { useEffect } from "react";
import { createBrowserHistory } from "history";

const sideEffects = () => {
  window.scrollTo(0, 0);
};

const history = createBrowserHistory();

const useAppHistory = () => {
  useEffect(() => {
    // Run sideEffects for initial page load.
    sideEffects();

    // history.listen() returns a cleanup function.
    return history.listen(sideEffects)
  },[]);

  return history;
};

export default useAppHistory;

