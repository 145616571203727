/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useContext, useEffect } from "react";
import { Honeybadger } from '@honeybadger-io/react'

import useLazySweepstakesQuery from "../../../lib/useLazySweepstakesQuery";
import { ToolContext } from "../ToolProvider";
import Field from "../Field";
import { getPromoCodeQuery } from "./queries";
import * as UI from "./ui";

const PromoCodeForm = () => {
  const { promoCode, setPromoCode } = useContext(ToolContext);
  const [ input, setInput ] = useState((promoCode && promoCode.name) || "");
  const [ dirty, setDirty ] = useState(false);
  const [ getPromoCode, { data, loading, called, error }] = useLazySweepstakesQuery(getPromoCodeQuery);

  const promoCodeName = promoCode && promoCode.name;;

  if(error) {
    Honeybadger.notify(error);
  }

  useEffect(() => {
    if(data) {
      const { promoCode } = data;
      setPromoCode(promoCode);
    }
  }, [ data ])

  const handleSubmit = e => {
    e.preventDefault();
    setDirty(false);
    getPromoCode({ variables: { name: input }});
  }

  const handleChange = e => {
    const val = e.target.value;
    const cleaned = val.replace(/[^A-Za-z0-9-_]+/g, "");
    setDirty(true);
    setInput(cleaned);
  }

  const disabled = loading || promoCodeName === input;
  const showError = called && !loading && !dirty && input !== "" && data && data.promoCode === null;

  return (
    <UI.Form onSubmit={handleSubmit}>
      <UI.InlineFieldGroup>
        <Field id="promoCode" label="Promo Code" value={input} onChange={handleChange} />
        <UI.Submit value="Apply" disabled={disabled} />
      </UI.InlineFieldGroup>
      <UI.Error showError={showError}>Sorry - that code has expired or is not valid.</UI.Error>
    </UI.Form>
  );
};

export default PromoCodeForm;
