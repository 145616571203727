import React, { useContext } from "react";

import { ToolContext, DONATION_LEVEL_STEP, DONOR_INFO_STEP, PAYMENT_STEP, RECEIPT_STEP } from "./ToolProvider";
import Switch from "./Switch";
import Page from "./Page";
import InputStep from "./InputStep";
import PaymentStep from "./PaymentStep";
import ReceiptStep from "./ReceiptStep";

const Tool = ({ sweepstakes, nonprofit }) => {
  const { setStep } = useContext(ToolContext);

  return (
    <Page sweepstakes={sweepstakes} nonprofit={nonprofit}>
      <Switch>
        <InputStep
          steps={[DONATION_LEVEL_STEP, DONOR_INFO_STEP]}
          sweepstakes={sweepstakes}
          onComplete={() => setStep(PAYMENT_STEP)}
        />
        <PaymentStep
          steps={PAYMENT_STEP}
          sweepstakes={sweepstakes}
          onComplete={() => setStep(RECEIPT_STEP)}
        />
        <ReceiptStep
          steps={RECEIPT_STEP}
          sweepstakes={sweepstakes}
          nonprofit={nonprofit}
        />
      </Switch>
    </Page>
  );
};

export default Tool;
