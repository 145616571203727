import { gql } from "@apollo/client";

export const shareQuery = gql`
  query GetShareInfo($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      publicUrl
      prize {
        id
        name
      }
      nonprofit {
        id
        name
      }
    }
  }
`
