import styled from "styled-components";

export const Heading = styled.div`
  font-size: 1.5em;
  margin-bottom: 10px;
  padding: 10px 0;

  @media screen and (min-width: 768px) {
    font-size: 2em;
    margin-bottom: 20px;
    padding: 20px 0;
  }
`;

export const ThankYou = styled.p`
  font-size: 1.125em;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const Message = styled.p`
  color: #555555;
  margin-bottom: 20px;
  line-height: 1.25em;
`;

export const CTA = styled.div`
  max-width: 300px;
`;

export const ErrorMessage = styled.div`
  color: #fff;
  background-color: #db3a34;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.75em;
  border-radius: 2px;
`
