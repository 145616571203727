import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

export const FloatingLayout = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }

  position: fixed;
  bottom: 40px;
  right: 0;
`;

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.root = document.getElementById('fab');
    this.el = document.createElement('div');
  }

  componentDidMount() {
    this.root.appendChild(this.el);
  }

  componentWillUnmount() {
    this.root.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

const FloatingAction = ({ children }) => (
  <Portal>
    <FloatingLayout>
      {children}
    </FloatingLayout>
  </Portal>
);

export default FloatingAction;

