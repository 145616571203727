import styled from "styled-components";

export const Title = styled.h2`
  border-bottom: 1px solid #333;
  font-weight: 700;
  padding-bottom: 20px;
  margin: 40px auto;

  @media screen and (max-width: 1024px) {
    margin: 30px auto;
    font-size: 1.5em;
  }

  @media screen and (max-width: 768px) {
    margin: 20px auto;
    font-size: 1.25em;
  }
`

export const Faq = styled.div`
  margin-bottom: 20px;

  &:after {
    display: block;
    content: "";
    background-color: #c1c1c1;
    height: 1px;
    width: 150px;
    margin: 0 0 20px 20px;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`

export const Question = styled.div`
  position: relative;
  padding: 10px 0 10px 20px;
  font-weight: 800;
  cursor: pointer;

  &:before {
    position: absolute;
    top: 50%;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 9px;
    border-color: transparent transparent transparent #222;
    display: inline-block;
    transition: all 0.05s linear;
    transform: translateX(-20px) translateY(-50%) ${ props => props.open? 'rotate(90deg)' : '' };
  }
`

export const Answer = styled.div`
  padding: 10px 0;
  font-size: 14px;
  line-height: 1.6em;
  overflow: hidden;
  margin-bottom: ${props => props.open ? '0' : '20px'};
  padding: ${props => props.open ? '10px 20px' : '0'};
  max-height: ${props => props.open ? 'auto' : '0'};
`
