import React from 'react';
import { DateTime } from "luxon";

import * as UI from "./ui";

const WinnerSnapshot = ({ winnerSnapshot, sweepstakes, isArchived }) => {
  const selectionDate = DateTime.fromISO(winnerSnapshot.drawnAt, { zone: sweepstakes.timezone });

  return (
    <UI.Layout>
      <UI.Banner isArchived={isArchived}>Congratulations to<br />our winner!</UI.Banner>
      <UI.Info>
        <UI.Section>
          <UI.Date>{selectionDate.toLocaleString(DateTime.DATE_MED)}</UI.Date>
          <UI.Name>{winnerSnapshot.name}</UI.Name>
          <UI.Location>{winnerSnapshot.location}</UI.Location>
        </UI.Section>
        <UI.Section>
          <UI.Label>Winning Entry</UI.Label>
          <UI.Number>{winnerSnapshot.number}</UI.Number>
        </UI.Section>
      </UI.Info>
    </UI.Layout>
  );
}

export default WinnerSnapshot;
