import styled from "styled-components";
import ReactQRCode from "react-qr-code";

export const QrCode = styled(ReactQRCode)`
  width: 300px;
  height: 300px;
`;

export const QrLayout = styled.div`
  display: ${({ mobile }) => mobile ? 'flex' : 'block'};
  flex-direction: column;
  align-items: center;
`;

export const Heading = styled.div`
  font-size: 1.5em;
  margin-bottom: 10px;
  padding: 10px 0;

  @media screen and (min-width: 768px) {
    font-size: 2em;
    margin-bottom: 20px;
    padding: 20px 0;
  }
`;

export const ThankYou = styled.p`
  font-size: 1.125em;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const Message = styled.p`
  color: #555555;
  margin-bottom: 20px;
  line-height: 1.25em;
`;
