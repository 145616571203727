import styled from "styled-components";
import { HashLink } from "react-router-hash-link"

export const Heading = styled.h3`
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25em;

  @media screen and (min-width: 767px) {
    font-size: 1.125em;
    line-height: 1.125em;
  }

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

export const OverviewLayout = styled.ul`
  border: 1px solid #e3e3e3;
  border-bottom: none;
  display: flex;
  flex-wrap: wrap;
  border-radius: 2px;
  color: #1c1c1c;

  & + & {
    margin-top: 10px;
  }
`;

export const OverviewHeading = styled.div`
  text-align: center;
  background-color: #e3e3e3;
  font-size: 0.75em;
  font-weight: 700;
  width: 100%;
  padding: 10px 0;
  text-transform: uppercase;
`;

export const OverviewItem = styled.li`
  flex: 1 1 50%;
  min-width: 50%;
  border-bottom: 1px solid #e3e3e3;
  padding: 20px;
`;

export const Label = styled.div`
  text-transform: uppercase;
  font-size: 0.625em;
  line-height: 1.6em;
  font-weight: 300;
`;

export const Value = styled.div`
  font-size: 0.875em;
  line-height: 1.5em;
`;

export const CashAlternative = styled.div`
  font-size: 1.5em;
  line-height: 1.5em;
  font-weight: 700;
  text-align: center;
`;

export const OpenPrizeDetails = styled.button`
  display: block;
  font-weight: bold;
  padding: 10px 30px;
  border: none;
  border-radius: 3px;
  background-color: #67778d;
  color: #ffffff;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const PrizeDetailsCopy = styled.div`
  li {
    ul {
      margin-bottom: 10px;
    }

    li {
      list-style-type: '–';
      text-indent: 0.5em;
    }
  }
`;

export const AmoeLink = styled(HashLink)`
  display: block;
  color: #000000;
  font-size: 0.75em;
  text-align: center;
  text-decoration: underline;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const WinnerDetailsTitle = styled.h1`
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25em;
  margin-bottom: 16px;

  @media screen and (min-width: 767px) {
    font-size: 1.125em;
    line-height: 1.125em;
  }
`;

export const WinnerDetailsList = styled.ul`
  list-style-type: decimal;
  font-size: 1em;
  margin-bottom: 16px;
  margin-left: 10px;
  padding-left: 10px;

  li {
    line-height: 1.6em;
  }
`;
