import React from "react";

import Layout from "../Layout";
import * as UI from "./ui";

import TotalDonations from './TotalDonations';
import Share from '../Share';
import DonateNow from "../DonateNow";
import { ScheduleMask } from "../../lib/schedule";

const Subheader = ({ totalDonations, threshold }) => {
  return (
    <UI.Background>
      <UI.Layout>
        <Layout.FullWidth alignItems="center">

          <Layout.FixedColumn span={3} breakpoint="belowTablet" breakwidth="100%">
            <TotalDonations totalDonations={totalDonations} />
          </Layout.FixedColumn>

          <Layout.FlexibleColumn span={[5,12]} showAbove="tablet">
            <ScheduleMask enabledPeriods={["review", "open", "closing", "grace", "closed", "drawn"]}>
              <Share />
            </ScheduleMask>
          </Layout.FlexibleColumn>

          <Layout.FlexibleColumn span={[4,5]} showAbove="tablet">
            <ScheduleMask enabledPeriods={["open", "closing"]}>
              <DonateNow />
            </ScheduleMask>
          </Layout.FlexibleColumn>

        </Layout.FullWidth>
      </UI.Layout>
    </UI.Background>
  )
}

export default Subheader;
