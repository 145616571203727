import React from 'react';
import styled from "styled-components"
import { Link } from 'react-router-dom';

const Button = styled(Link)`
  background-color: #00C19D;
  border: none;
  border-radius: 2px;
  color: #fff;
  display: block;
  font-size: 1em;
  font-weight: 700;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  ${props => props.floating && `
    width: auto;
    font-size: 1.25em;
    padding: 20px;
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.45);
  `}
`;

// For some reason, supplying an onTouchEnd event
// prevents Mobile Safari from requiring two clicks.
const NOOP = () => {};

const DonateNow = (props) => {
  const text = props.floating ? 'Enter Now!' : 'Donate Now to Enter';
  return <Button {...props} onTouchEnd={NOOP} to="/donate">{text}</Button>
};

export default DonateNow;
