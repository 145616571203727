import styled from "styled-components";

export const Title = styled.h2`
  max-width: 1200px;
  padding: 0 20px;
  margin: 40px auto;
  font-size: 2em;
  font-weight: 700;

  @media screen and (max-width: 1024px) {
    margin: 30px auto;
    font-size: 1.5em;
  }

  @media screen and (max-width: 768px) {
    margin: 20px auto;
    font-size: 1.25em;
  }
`;
