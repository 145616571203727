import React, { useState } from "react";
import { useWindowWidth } from '@react-hook/window-size/throttled'

export const MODE_BREAKPOINT = 768;

export const DONATION_LEVEL_STEP = 0;
export const DONOR_INFO_STEP = 1;
export const PAYMENT_STEP = 2;
export const RECEIPT_STEP = 3;

export const ToolContext = React.createContext();

const STEP_NAMES_FOR_EVENT = [];

STEP_NAMES_FOR_EVENT[DONATION_LEVEL_STEP] = "Donation Level Selection";
STEP_NAMES_FOR_EVENT[DONOR_INFO_STEP] = "Donor Input";
STEP_NAMES_FOR_EVENT[PAYMENT_STEP] = "Payment Input";
STEP_NAMES_FOR_EVENT[RECEIPT_STEP] = "Receipt Display";

const ToolProvider = ({ bootstrap, children, expired }) => {
  const width = useWindowWidth();
  const mode = (width < MODE_BREAKPOINT) ? "mobile" : "desktop";

  const [ donor, setDonor ] = useState({
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "US",
    email: "",
    phoneNumber: "",
  });

  const [ donationLevel, setDonationLevel ] = useState(bootstrap.donationLevel);
  const [ promoCode, setPromoCode] = useState(bootstrap.promoCode);
  const [ paymentIntent, setPaymentIntent ] = useState({});

  const [ step, setStepValue ] = useState(DONATION_LEVEL_STEP);

  const setStep = nextStep => {
    setStepValue(nextStep);
  };

  const contextValue = {
    mode,
    step,
    setStep,
    donor,
    setDonor,
    promoCode,
    setPromoCode,
    donationLevel,
    setDonationLevel,
    paymentIntent,
    setPaymentIntent,
    expired,
  };

  return (
    <ToolContext.Provider value={contextValue}>
      {children}
    </ToolContext.Provider>
  )
}

export default ToolProvider;

