import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { Honeybadger } from '@honeybadger-io/react'

import { ToolContext, DONOR_INFO_STEP } from "./ToolProvider";
import Layout from "../Layout";
import * as UI from "./ui";
import DonorForm from "./DonorForm";
import { encryptDonationInfoMutation } from "./mutations";

const ERROR = "Uh-oh! Sorry, but something has gone wrong. To try again please submit the form again. We’ve notified our support team of this issue.";

const notifyFailure = (error) => {
  Honeybadger.notify(error);
}

const InputStep = ({ sweepstakes, onComplete }) => {
  const { donor, setEncryptedData } = useContext(ToolContext);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ encryptDonationInfo, { loading }] = useMutation(encryptDonationInfoMutation);

  const saveDonation = () => {
    setErrorMessage('');

    const data = JSON.stringify({
      firstName: donor.firstName,
      lastName: donor.lastName,
      email: donor.email,
      phoneNumber: donor.phoneNumber,
      address: {
        line1: donor.addressLine1,
        line2: donor.addressLine2,
        city: donor.city,
        state: donor.state,
        postalCode: donor.zipCode,
        country: donor.country,
      }
    });

    encryptDonationInfo({ variables: { data } })
      .then(({data: { encryptData: { encryptedData, error }}}) => {
        if(encryptedData) {
          setEncryptedData(encryptedData);
          onComplete();
        } else {
          notifyFailure(error.message);
          setErrorMessage(ERROR);
        }
      });
  }

  return (
    <Layout.FullWidth fold>
      <DonorForm
        steps={DONOR_INFO_STEP}
        setDonorField={() => {}}
        onComplete={saveDonation}
        disabled={loading}
      />
      <Layout.FullWidthColumn>
        { errorMessage && <UI.ErrorMessage>{errorMessage}</UI.ErrorMessage> }
      </Layout.FullWidthColumn>
    </Layout.FullWidth>
  );
};

export default InputStep;
