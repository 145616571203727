import React from 'react';

import Layout from "../Layout";
import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import { footerQuery } from "./gql";
import * as UI from "./ui";

const Footer = ({ discrete }) => {
  const { data } = useSweepstakesQuery(footerQuery);

  const nonprofitName = data?.sweepstakes?.nonprofit?.name;
  const privacyPolicyWebsite = data?.sweepstakes?.nonprofit?.profile?.privacyPolicyWebsite;

  return (
    <UI.Footer discrete={discrete}>
      <Layout.FullWidth alignItems="center" fold>
        <Layout.FixedColumn span={3} breakpoint="belowPhone">
          <UI.PoweredBy href='https://www.tapkat.win/' target='_blank' rel="noopener noreferrer">
            <UI.Logo />
          </UI.PoweredBy>
        </Layout.FixedColumn>

        <Layout.FlexibleColumn span={[9,17]} breakpoint="belowTablet">
          { !discrete && (
            <UI.Nav>
              <a href='https://assets.tapkat.org/TapKat-entrant-privacy-policy.pdf' target='_blank' rel="noopener noreferrer">TapKat Privacy Policy</a>
              { privacyPolicyWebsite && <a href={privacyPolicyWebsite} target='_blank' rel="noopener noreferrer">{nonprofitName} Privacy Policy</a> }
            </UI.Nav>
          )}
          <UI.Copyright>
            © Copyright {(new Date()).getFullYear()} All rights reserved.
            <br />
            TapKat and the TapKat logo are trademarks of TapKat Solutions LLC
          </UI.Copyright>
        </Layout.FlexibleColumn>
      </Layout.FullWidth>
    </UI.Footer>
  );
}

export default Footer;
