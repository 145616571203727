import React, { useEffect } from 'react';
import { path, isNil } from "ramda";

import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import ClosedNotice from "../ClosedNotice";
import WinnerSnapshot from "./WinnerSnapshot";
import { winnerQuery } from "./queries";
import confetti from "./confetti";

const POLL_INTERVAL_MS = 15 * 1000;

const DrawnNotice = ({ sweepstakes, isArchived }) => {
  const { data, loading, error, stopPolling } = useSweepstakesQuery(winnerQuery, {
    pollInterval: POLL_INTERVAL_MS,
  });

  const winnerSnapshot = path(['sweepstakes', 'prize', 'winnerSnapshot'], data);

  useEffect(() => {
    if(!isNil(winnerSnapshot)) {
      stopPolling();
      confetti();
    }
  }, [stopPolling, winnerSnapshot])

  if(error || loading) {
    return null;
  }

  if(isNil(winnerSnapshot)) {
    return <ClosedNotice sweepstakes={sweepstakes} />
  }

  return <WinnerSnapshot winnerSnapshot={winnerSnapshot} sweepstakes={sweepstakes} isArchived={isArchived} />
}

export default DrawnNotice;
