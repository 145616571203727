import styled from "styled-components";

export const Layout = styled.div`
  border: #e3e3e3 solid 1px;
  text-align: center;
`

export const Banner = styled.div`
  padding: 20px;
  background-color: ${props => props.isArchived ? "#fff" : "#00c19d"};
  color: ${props => props.isArchived ? "#000" : "#fff"};
  border-bottom: ${props => props.isArchived ? "#e3e3e3 solid 1px" : "none"};
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25em;
`;

export const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Section = styled.div`
  flex: 1;
  min-width: 100%;

  border-bottom: #e3e3e3 solid 1px;
  line-height: 1.5em;
  padding: 20px;

  &:last-child {
    border: none;
  }

  @media screen and (min-width: 440px) and (max-width: 768px) {
    border-bottom: none;
    min-width: auto;
    border-right: #e3e3e3 solid 1px;
  }
`;

export const Label = styled.div`
  color: #666;
  margin-bottom: 5px;
  font-size: 0.875em;
`;

export const Name = styled.div`
  font-weight: 700;
  font-size: 1.35em;
`;

export const Date = styled.div`
  color: #666;
  margin-bottom: 5px;
  font-size: 0.875em;
`;

export const Number = styled.div`
  font-weight: 700;
  font-size: 2em;

  &:before {
    content: "#";
  }
`;

export const Location = styled.div`
  font-size: 0.875em;
`;

