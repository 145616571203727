import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import ErrorHandler from "./lib/ErrorHandler";

import App from './components/App';
import CssReset from "./lib/CssReset";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: process.env.REACT_APP_API_URL,
  })
});

const root = document.getElementById('root');

ReactDOM.render(
  <ErrorHandler>
    <CssReset />
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </ErrorHandler>, root);
