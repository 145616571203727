import React from 'react';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import * as UI from "./ui";

const Email = ({ emailSubject, emailBodyText, url }) => {
  const onClick = () => {
    const root = 'mailto:';
    const newLine = '%0D%0A';
    const body = `${emailBodyText}${newLine}${newLine}${url}`;
    const mailtoUrl = `${root}?subject=${emailSubject}&body=${body}`;

    window.location.href = mailtoUrl;
  }

  return (
    <UI.Button onClick={onClick}>
      <UI.Icon icon={faEnvelope} />
    </UI.Button>
  );
}

export default Email;

