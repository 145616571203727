import React from "react";

import ToolProvider from "./ToolProvider";
import Tool from "./Tool";

const FulfillmentTool = (props) => {
  return (
    <ToolProvider {...props}>
      <Tool {...props} />
    </ToolProvider>
  );
}

export default FulfillmentTool;
