import React from 'react';
import Markdown from "markdown-to-jsx";
import { HashLink } from "react-router-hash-link"
import styled from 'styled-components';

const scrollWithOffset = el => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const headerHeight = document.getElementById('header').offsetHeight;
  const yOffset = -1 * (headerHeight + 10);
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

const Styler = styled.div`
  h1, h2, h3, h4, h5, h6 {
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.25em;
    margin-bottom: 16px;

    @media screen and (min-width: 767px) {
      font-size: 1.125em;
      line-height: 1.125em;
    }
  }

  p {
    font-size: 1em;
    line-height: 1.6em;
    margin-bottom: 16px;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 800;
  }

  hr {
    margin: 0.83em 0;
    padding: 0;
  }

  ol,
  ul {
    font-size: 1em;
    margin-bottom: 16px;
    margin-left: 10px;
    padding-left: 10px;

    li {
      line-height: 1.6em;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  blockquote {
    margin: 0;
    margin-left: 1.6em;
    padding: 0.4em 1.6em;
    background: #f9f9f9;
  }

  pre {
    margin: 0;
    padding: 1.6em;
    background: #f9f9f9;
  }

  code {
    font-family: monospace;
    line-height: 1.6em;
  }
`;

const AnchorAdapter = ({ href, ...rest }) => {
  if(href && !href.includes('http')){
    return <HashLink to={href} {...rest} scroll={scrollWithOffset} />;
  }

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a href={href} {...rest} rel="noopener noreferrer" target="_blank" />;
};

const options = {
  wrapper: Styler,
  forceWrapper: true,
  forceBlock: true,
  overrides: {
    a: AnchorAdapter,
  },
};

const Copy = ({ markdown }) => markdown && <Markdown options={options}>{markdown}</Markdown>

export default Copy;
