import React from "react";
import { times } from  "ramda";

import * as UI from "./ui";

const PlaceholderLevels = () => (
  <UI.Layout>
    { times(index => (
      <UI.Item key={index}>
        <UI.PlaceholderLevel />
      </UI.Item>
    ), 8)}
    <UI.PlaceholderNotice>
      Set your donation amounts in the Donation Amounts step.
    </UI.PlaceholderNotice>
  </UI.Layout>
);

export default PlaceholderLevels;
