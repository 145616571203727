import React, { useContext } from "react";

import Layout from "../../Layout";
import { ToolContext } from "../ToolProvider";
import DonorCard from "../DonorCard";
import * as UI from "./ui";

const QrCodeStep = () => {
  const { donor, encryptedData, mode } = useContext(ToolContext);

  return (
    <React.Fragment>
      <Layout.FullWidth fold>
        <Layout.FullWidthColumn>
          <UI.Heading>In-person Donation</UI.Heading>
        </Layout.FullWidthColumn>
        <Layout.FlexibleColumn span={[7,15]} breakpoint="belowTablet" spaced>
          <UI.ThankYou>Almost done...</UI.ThankYou>
          <UI.Message>
            Please present this to a staff member with your payment to complete your donation.
          </UI.Message>
          <UI.QrLayout mobile={mode === "mobile"}>
            <UI.QrCode value={encryptedData} />
          </UI.QrLayout>
        </Layout.FlexibleColumn>
        <Layout.FixedColumn span={5} breakpoint="belowTablet" spaced>
          <DonorCard donor={donor} />
        </Layout.FixedColumn>
      </Layout.FullWidth>
    </React.Fragment>
  );
}

export default QrCodeStep;
