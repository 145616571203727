import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CountdownTimerLayout = styled.div`
  position: absolute;
  right: 10px;
  padding: 5px 10px 8px;
  background: #FF5822;
  color: #FFFFFF;
  font-size: 0.875em;
  font-weight: 400;
  font-style: italic;
  box-shadow: box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.45);

  transform: ${props => props.enabled? "translateY(0%)" : "translateY(-100%)"};
  transition: all 500ms ease-in-out;
`;

export const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;
