import React from 'react';
import { Navigate } from "react-router-dom";

import SweepstakesHeadTags from "../SweepstakesHeadTags";
import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import { useSchedulePeriod } from "../../lib/schedule";
import { ScheduleMask } from "../../lib/schedule";
import Page from "../Page";
import Copy from '../Copy';
import SuperFooter from '../SuperFooter';
import FloatingAction from "../../lib/FloatingAction";
import DonateNow from "../DonateNow";
import { rulesQuery } from "./queries";
import Layout from "../Layout";
import * as UI from "./ui";

const Rules = () => {
  const period = useSchedulePeriod();
  const isArchived = period === "archived";
  const { data, loading, error } = useSweepstakesQuery(rulesQuery)

  if (isArchived) {
    return <Navigate to="/" replace />;
  }

  if(loading || error) {
    return null;
  }

  const {
    sweepstakes,
    sweepstakes: {
      rules: {
        content,
      }
    }
  } = data;

  return (
    <Page>
      <SweepstakesHeadTags sweepstakes={sweepstakes} title="Official Rules & Regulations" />
      <Layout.FullWidth spaced>
        <Layout.FlexibleColumn span={[1,20]}>
          <UI.Header>
            <UI.Title>Official Sweepstakes Rules and Regulations</UI.Title>
          </UI.Header>
          <UI.Document>
            <Copy markdown={content} />
          </UI.Document>
        </Layout.FlexibleColumn>
      </Layout.FullWidth>
      <SuperFooter
        sweepstakes={sweepstakes}
      />
      <ScheduleMask enabledPeriods={["open", "closing"]}>
        <FloatingAction>
          <DonateNow floating />
        </FloatingAction>
      </ScheduleMask>
    </Page>
  );
}

export default Rules;
