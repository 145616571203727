import React from 'react';
import { DateTime } from "luxon";
import DrawingLocation from "../DrawingLocation";

import * as UI from "./ui";

const DrawingClosedAside = ({ sweepstakes }) => {
  const winningTicketDrawnAt = DateTime.fromISO(sweepstakes.winningTicketDrawnAt, { zone: sweepstakes.timezone });

  return (
    <UI.Layout>
      <UI.Banner>Sweepstakes has closed.</UI.Banner>
      <UI.Info>
        <UI.Section>
          <UI.Label>Drawing Date</UI.Label>
          <UI.Value>{winningTicketDrawnAt.toLocaleString(DateTime.DATE_MED)}</UI.Value>
        </UI.Section>
        <UI.Section>
          <UI.Label>Drawing Time</UI.Label>
          <UI.Value>{winningTicketDrawnAt.toFormat("t ZZZZ")}</UI.Value>
        </UI.Section>
        <UI.Section>
          <UI.Label>Location</UI.Label>
          <UI.Location>
            <DrawingLocation location={sweepstakes.location}/>
          </UI.Location>
        </UI.Section>
      </UI.Info>
    </UI.Layout>
  );
};

export default DrawingClosedAside;
