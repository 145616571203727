import React from 'react';

import { ScheduleSwitch } from "../../lib/schedule";
import DonationLevelsAside from '../DonationLevelsAside';
import ClosedNotice from '../ClosedNotice';
import EndedNotice from './EndedNotice';

const Aside = ({ sweepstakes, isArchived }) => (
  <ScheduleSwitch>
    <DonationLevelsAside periods={["review", "open", "closing"]} />
    <ClosedNotice periods={["grace", "closed"]} sweepstakes={sweepstakes} />
    <EndedNotice periods={["drawn", "archived"]} sweepstakes={sweepstakes} isArchived={isArchived} />
  </ScheduleSwitch>
);

export default Aside;
