import styled from "styled-components";

export const Form = styled.form`
  margin: auto -5px 10px;
`

export const InlineFieldGroup = styled.div`
  display: flex;
`;

export const Submit = styled.input.attrs({
  type: "submit",
})`
  appearance: none;
  background-color: #00C19D;
  border: none;
  border-radius: 2px;
  color: #fff;
  display: block;
  font-size: 0.875em;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  padding: 0 20px;

  &:hover {
    background-color: #00755f;
  }

  &:disabled {
    background-color: #cccccc;
  }
`

export const Error = styled.div`
  color: #db3a34;
  font-size: 0.75em;
  margin: 5px 5px;
  visibility: ${props => props.showError ? 'visible' : 'hidden'};
`;
