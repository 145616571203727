import { gql } from "@apollo/client";

export const sweepstakesAnalyticsQuery = gql`
  query SweepstakesAnalyticsQuery($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      googleAdsConversionId
      googleAnalyticsPropertyId
      facebookPixelId
    }
  }
`;
