import React from 'react';
import { none, prop } from "ramda";

import * as UI from "./ui";

const WinnerItem = ({ name, snapshot }) => {
  if (!snapshot) {
    return null;
  }

  return (
    <li>{`${name} – #${snapshot.number} – ${snapshot.name}`}</li>
  );
}

const SweepstakesDesscription = ({ sweepstakes }) => {
  const { winnerSnapshot: grandPrizeSnapshot } = sweepstakes.prize;
  const additionalPrizes = sweepstakes.additionalPrizes;

  if (none(prop("winnerSnapshot"), additionalPrizes)) {
    return null;
  }

  return (
    <>
      <UI.WinnerDetailsTitle>
        Congratulations to our winners:
      </UI.WinnerDetailsTitle>
      <UI.WinnerDetailsList>
        <WinnerItem key="WinnerDetail-1" name="Grand Prize" snapshot={grandPrizeSnapshot} />
        {additionalPrizes.map(({ name, winnerSnapshot }, i) => (
          <WinnerItem key={`WinnerDetail-${i + 2}`} name={name} snapshot={winnerSnapshot} />
        ))}
      </UI.WinnerDetailsList>
    </>
  )
};

export default SweepstakesDesscription;
