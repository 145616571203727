import { gql } from "@apollo/client";

export const rulesQuery = gql`
  query GetRules($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id

      rules {
        content
      }

      nonprofit {
        id
        profile {
          id
          email
        }
      }
    }
  }
`

