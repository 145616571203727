import React, { useContext } from "react";

import Layout from "../../Layout";
import { ScheduleSwitch } from "../../../lib/schedule";
import QuickSelect from '../../QuickSelect';
import { ToolContext } from "../ToolProvider";
import * as UI from "./ui";

const ReceiptStep = () => {
  const { alreadyFulfilled } = useContext(ToolContext);

  return (
    <React.Fragment>
      <Layout.FullWidth fold id="receipt">
        <Layout.FullWidthColumn>
          <UI.Heading>Thank you for participating</UI.Heading>
        </Layout.FullWidthColumn>
        <Layout.FlexibleColumn span={[7,15]} breakpoint="belowTablet" spaced>
          { alreadyFulfilled && <UI.ErrorMessage>Sorry you have already redeemed this request for a entry.</UI.ErrorMessage> }
          <UI.ThankYou>Check your email for your entry.</UI.ThankYou>
          <UI.Message>
            This sweepstakes is a fundraising campaign for our nonprofit organization. Please consider supporting our cause with a donation.
          </UI.Message>
        </Layout.FlexibleColumn>
        <Layout.FixedColumn span={5} breakpoint="belowLaptop" spaced>
          <ScheduleSwitch>
            <QuickSelect periods={["open", "closing"]} />
          </ScheduleSwitch>
        </Layout.FixedColumn>
      </Layout.FullWidth>
    </React.Fragment>
  );
}

export default ReceiptStep;
