import styled from "styled-components";

export const Layout = styled.div`
`;

export const Captcha = styled.div`
  padding: 0 10px;
  margin-bottom: 10px;
`;

export const FieldLayout = styled.div`
  padding: 0 5px;
  flex: ${props => props.weight || 1};
`;

export const Form = styled.form`
  margin: auto -5px 20px;
  ${FieldLayout} {
    margin-bottom: 10px;
  }
`

export const FieldBody = styled.div`
  border: 1px solid #ddd;
  border-radius: 3px;
  position: relative;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  text-transform: uppercase;
  position: absolute;
  color: #979797;
  top: 5px;
  left: 10px;
  font-size: 0.8125em;
  transform-origin: top left;
  transition: transform 100ms linear;
  transform: ${props => props.empty ? "scale(1.0)" : "scale(0.8)"};
  cursor: text;
  user-select: none;

  input:focus + & {
    transform: scale(0.8);
  }
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  padding: 20px 10px 5px;
  background: none;
  /* This is set to 1em = 16px so that Mobile Safari does not "help" by zooming in. */
  font-size: 1em;
`;

export const Select = styled.select`
  width: 100%;
  border: none;
  padding: 20px 10px 5px;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat calc(100% - 5px) 50%;
  appearance:none;
  /* This is set to 1em = 16px so that Mobile Safari does not "help" by zooming in. */
  font-size: 1em;
`;

export const FieldGroup = styled.div`
  display: flex;
`;

export const ActionButton = styled.input.attrs({
  type: "submit",
})`
  appearance: none;
  background-color: #00C19D;
  border: none;
  border-radius: 2px;
  color: #fff;
  display: block;
  font-size: 1em;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 40px;
  margin: 0 5px;

  &:hover {
    background-color: #00755f;
  }

  &:disabled {
    background-color: #cccccc;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const Instructions = styled.div`
  padding: 10px 5px;
  color: #333333;
  margin-bottom: 10px;
`

export const Legal = styled.div`
  padding: 10px;
  font-size: 0.75em;
  color: #333333;
`

export const ErrorMessage = styled.div`
  color: #fff;
  background-color: #db3a34;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.75em;
  border-radius: 2px;
`

export const Receipt = styled.div`
  margin-bottom: 10px;
  font-size: 0.875em;
  line-height: 1.25em;

  @media print {
    margin-bottom: 50px;
  }

  strong {
    font-weight: bold;
  }
`;

export const Address = styled.address`
  padding: 20px 0;
  font-weight: bold;
  font-size: 1.25em;
`;

export const DownloadButton = styled.a`
  background-color: #00C19D;
  border: none;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 1em;
  font-weight: 700;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
`;
