import React from 'react';
import { Link } from "react-router-dom";

import Layout from "../Layout";
import * as UI from "./ui";
import MailinTicketRequestForm from "../MailinTicketRequestForm";

const OpenContent = ({ sweepstakes }) => (
  <Layout.FullWidth fold>
    <Layout.FullWidthColumn>
      <UI.Header>
        <UI.Heading>Alternative Method of Entry</UI.Heading>
        <UI.Subheading>Enter without a Donation</UI.Subheading>
        <UI.Notice>Please read Sweepstakes <Link to="/rules">Rules and Regulations</Link> for details.</UI.Notice>
      </UI.Header>
    </Layout.FullWidthColumn>
    <MailinTicketRequestForm sweepstakes={sweepstakes}  />
  </Layout.FullWidth>
);

export default OpenContent;
