import React, { useContext } from 'react';

import { ScheduleGuard } from "../../lib/schedule";
import Layout from "../Layout";
import DonationLevel from '../DonationLevel';
import { ToolContext } from "./ToolProvider";
import PromoCodeForm from "./PromoCodeForm";
import * as UI from "./ui";

const DonationLevels = ({ donationLevels, onComplete, error }) => {
  const { promoCode, donationLevel = {}, setDonationLevel } = useContext(ToolContext);

  const handleClick = (donationLevel) => {
    setDonationLevel(donationLevel);
    onComplete();
  }

  return (
    <Layout.FixedColumn span={5} breakpoint="belowLaptop" spaced>
      <UI.SubstepHeading>Select Your Donation Amount</UI.SubstepHeading>
      <PromoCodeForm />
      <UI.DonationLevelList>
        {donationLevels.map(item => (
          <UI.DonationLevelItem key={item.id}>
            <ScheduleGuard enabledPeriods={["open", "closing", "grace"]}>
              <DonationLevel
                donationLevel={item}
                promoCode={promoCode}
                onClick={handleClick}
                selected={donationLevel.level === item.level}
              />
            </ScheduleGuard>
          </UI.DonationLevelItem>
        ))}
      </UI.DonationLevelList>
      { error && <UI.ErrorMessage>{error}</UI.ErrorMessage> }
    </Layout.FixedColumn>
  );
}

export default DonationLevels;


