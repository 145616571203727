import React, { useState } from "react";
import styled from "styled-components";
import { useWindowWidth } from '@react-hook/window-size/throttled'

const Container = styled.div`
  position: relative;
  ${props => !props.open && `
    border-bottom: 1px solid #e3e3e3;
    overflow: hidden;
    max-height: 100px;
  `}
`;

const Fade = styled.div`
  background: linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,0.95));
  bottom: 0;
  height: 100px;
  position: absolute;
  width: 100%;
  opacity: ${props => props.open ? 0 : 1 }
`;

const Open = styled.div`
  background: #e3e3e3;
  border-radius: 0 0 3px 3px;
  color: #333;
  cursor: pointer;
  font-size: 0.875em;
  font-weight: bold;
  margin: 0 auto 20px;
  padding: 5px;
  text-align: center;
  user-select: none;
  width: 100px;
`

const ReadMore = ({description, children}) => {
  const width = useWindowWidth();
  const [ open, setOpen ] = useState(false);

  if(width > 768) {
    return children;
  }

  const onClick = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Container open={open}>
        {children}
        <Fade open={open}/>
      </Container>
      { !open && <Open onClick={onClick}>Read More</Open> }
    </React.Fragment>
  )
};

export default ReadMore;
