import React from 'react';

import QuickSelect from '../QuickSelect';
import * as UI from "./ui";

const scrollWithOffset = el => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const headerHeight = document.getElementById('header').offsetHeight;
  const yOffset = -1 * (headerHeight + 10);
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

const DonationLevelsAside = () => (
  <UI.Layout>
    <QuickSelect />
    <UI.AmoeLink to="/rules#how-to-enter" scroll={scrollWithOffset}>
      No donation necessary to enter, see details here
    </UI.AmoeLink>
  </UI.Layout>
);

export default DonationLevelsAside;
