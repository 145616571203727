import React, { useState } from "react";
import { DateTime } from "luxon";

import TopNotice from "../../lib/TopNotice";
import CountdownTimer from "./CountdownTimer";

const GracePeriodGuard = ({ schedule, children }) => {
  const period = schedule.grace;
  const initialExpiredValue = DateTime.fromISO(period[0]) < DateTime.local();

  const [ expired, setExpired ] = useState(initialExpiredValue);

  return (
    <React.Fragment>
      { !expired && (
        <TopNotice>
          <CountdownTimer period={schedule.grace} onEnd={() => setExpired(true)} />
        </TopNotice>
      )}
      {React.cloneElement(children, { expired })}
    </React.Fragment>
  );
};

export default GracePeriodGuard;
