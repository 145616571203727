import React from 'react';

import * as UI from "./ui";
import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import { shareQuery } from "./queries";
import FacebookButton from './Facebook';
import EmailButton from './Email';
import TwitterButton from './Twitter';

const buildProps = (receipt, data) => {
  const {
    sweepstakes: {
      publicUrl,
      prize,
      nonprofit
    }
  } = data;

  return receipt ? ({
    message: `Hey, I just donated to ${nonprofit.name} for a chance to win a ${prize.name}'. Check it out: ${publicUrl}`,
    url: publicUrl,
    emailSubject: `I just entered to win a ${prize.name}!`,
    emailBodyText: `Hey, I just donated to ${nonprofit.name} for a chance to win a ${prize.name}'. Check it out!`
  }) : ({
    message: `Check it out - you could win a ${prize.name}!`,
    url: publicUrl,
    emailSubject: `Check it out - you could win a ${prize.name}!`,
    emailBodyText: `Have you seen this amazing sweepstakes for a ${prize.name}? And proceeds support the nonprofit ${nonprofit.name}.`,
  });
}

const Share = ({ receipt }) => {
  const { data, loading, error } = useSweepstakesQuery(shareQuery);

  if(error || loading) {
    return null;
  }

  const props = buildProps(receipt, data);

  return (
    <UI.Layout>
      <UI.WidgetTitle>Share Sweepstakes</UI.WidgetTitle>
      <EmailButton {...props} />
      <TwitterButton {...props} />
      <FacebookButton {...props} />
    </UI.Layout>
  );
}

export default Share;
