import styled from "styled-components";

export const Layout = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #eee;
  border-top: none;
`;

export const Name = styled.div`
  font-weight: 700;
  font-size: 1.125em;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
`;

export const Address = styled.div`
  font-size: 0.875em;
  margin-bottom: 10px;
  color: #555555;
`;

export const Contact = styled.div`
  font-weight: 700;
  font-size: 0.875em;
  line-height: 1.5em
  color: #555555;
`;

