import React from 'react';
import { faHeart } from "@fortawesome/free-solid-svg-icons";

import Money from '../Money';
import * as UI from "./ui";

const TotalDonationsWidget = ({ totalDonations }) => (
  <UI.Widget>
    <UI.WidgetTitle>Donations Made</UI.WidgetTitle>
    <UI.WidgetValue>
      <UI.TotalIcon icon={faHeart} />
      <Money cents={totalDonations} />
    </UI.WidgetValue>
  </UI.Widget>
);

export default TotalDonationsWidget;
