import styled, { keyframes } from "styled-components";

const cardRadius = ({ cardWidth }) => Math.floor(cardWidth / 12);

export const Layout = styled.div`
  position: relative;
  display: inline-block;
  height: ${props => props.cardWidth}px;
  overflow: hidden;
  border-radius: ${cardRadius}px;
  background: #00C19D;
  color: #ffffff;
  box-shadow: 0 3px 6px 3px rgba(0,0,0,0.2);

  &:before {
    position: absolute;
    top: 6px;
    right: 6px;
    bottom: 6px;
    left: 6px;
    content: "";
    background: linear-gradient(0deg, rgba(0,0,0,0) 60%, rgba(255,255,255,0.4) 100%);
    border-radius: ${cardRadius}px;
  }

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background: linear-gradient(0deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 50%);
    border-radius: ${cardRadius}px;
  }
`;

export const Shine = styled.div`
  position: absolute;
  top: 0;
  bottom: 45%;
  width: 200%;
  background-color: rgba(255,255,255,0.15);
  border-bottom: 1px solid rgba(255,255,255,0.1);
  transform: rotate(15deg) translateX(-25%);
`;

const slide = ({ cardWidth }) => keyframes`
  0% {
    transform: translateY(0);
  }
  95% {
    transform: translateY(calc(-100% + ${cardWidth * 0.75}px));
  }
  100% {
    transform: translateY(calc(-100% + ${cardWidth}px));
  }
`;

export const NumberCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0 0 12px rgba(0,0,0,0.25);
  font-weight: 600;
  user-select: none;
`;

export const NumberList = styled.div`
  display: flex;
  flex-direction: column;
  animation-name: ${slide};
  animation-duration: ${props => props.duration}ms;
  animation-delay: ${props => props.delay}ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  ${NumberCard} {
    height: ${props => props.cardWidth}px;
    width: ${props => props.cardWidth}px;
    font-size: ${props => Math.floor(props.cardWidth / 16 / 1.5)}em;
  }
`;
