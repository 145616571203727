import { gql } from '@apollo/client';

export const pageSetupQuery = gql`
  query PageSetupQuery($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      headline
      onlineTicketRequestsEnabled

      prize {
        id
        name
      }
    }
  }
`;
