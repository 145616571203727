import { gql } from "@apollo/client";

export const donationLevelQuery = gql`
  query GetDonationLevels($id: SweepstakesSlug!, $promoCodeName: String) {
    sweepstakes(id: $id){
      id
      donationLevels {
        id
        level
        price
        rewardTickets
      }
    }
    promoCode(id: $id, name: $promoCodeName) {
      id
      name
      bonusPercent
    }
  }
`;

