import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { isNil, isEmpty } from "ramda";

import BootParameterProvider from "../../lib/BootParameterProvider";
import SweepstakesExistsGuard from "./SweepstakesExistsGuard";
import { ScheduleProvider } from "../../lib/schedule";
import AnalyticsTags from "../AnalyticsTags";
import IndexPage from '../IndexPage';
import FaqPage from '../FaqPage';
import AlternativeMethodOfEntryPage from '../AlternativeMethodOfEntryPage';
import RulesPage from '../RulesPage';
import DonationPage from '../DonationPage';
import InPersonDonationPage from "../InPersonDonationPage";
import FulfillmentPage from "../FulfillmentPage";
import WinnerPage from "../WinnerPage";

import useAppHistory from "./useAppHistory";

const BASENAME_REGEX = /^\/(?<marketing>[A-Za-z\d-]*)\/(?<id>[A-Za-z\d-]*)/;
const NULL_MATCH = { groups: {} };

const App = () => {
  const match = window.location.pathname.match(BASENAME_REGEX) || NULL_MATCH;
  const basename = match[0];
  const { id } = match.groups;

  if(isNil(id) || isEmpty(id)) {
    window.location.assign(process.env.REACT_APP_MISSING_SWEEPSTAKES_DESTINATION)
  }

  return (
    <BrowserRouter history={useAppHistory()} basename={basename}>
      <BootParameterProvider id={id}>
        <SweepstakesExistsGuard>
          <AnalyticsTags />
          <ScheduleProvider>
            <Routes>
              <Route index element={<IndexPage />} exact />
              <Route path="rules" element={<RulesPage />} />
              <Route path="donate" element={<DonationPage />} />
              <Route path="in-person" element={<InPersonDonationPage />} />
              <Route path="faq" element={<FaqPage />} />
              <Route path="amoe" element={<AlternativeMethodOfEntryPage />} />
              <Route path="winner" element={<WinnerPage />} />
              <Route path="redeem/:token" element={<FulfillmentPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </ScheduleProvider>
        </SweepstakesExistsGuard>
      </BootParameterProvider>
    </BrowserRouter>
  );
}

export default App;
