import React from 'react';

import useQueryString from "./useQueryString";

export const BootParameterContext = React.createContext();

const value = {};

const BootParameterProvider = ({ id, children }) => {
  const query = useQueryString();

  value.id = id;

  // Lock in first value we get for promo.
  if(!value.promo) {
    value.promo = query.promo;
  }

  // Allow level to be overwritten during navigation.
  value.level = query.level;

  return (
    <BootParameterContext.Provider value={value} >
      {children}
    </BootParameterContext.Provider>
  );
};

export default BootParameterProvider;
