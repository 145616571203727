import { gql } from "@apollo/client";

export const donationToolQuery = gql`
  query EventDonationToolQuery($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      headline

      nonprofit {
        id
        ein
        name
        paymentAccountId

        profile {
          id
          color
        }
      }
    }
  }
`;

