import { gql } from "@apollo/client";

export const faqQuery = gql`
  query GetFaqs($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id

      faqs {
        question
        answer
      }

      nonprofit {
        id
        profile {
          id
          email
        }
      }
    }
  }
`

