import styled from "styled-components";

import Image from "../Image";

export const Heading = styled.h3`
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25em;

  @media screen and (min-width: 767px) {
    font-size: 1.125em;
    line-height: 1.125em;
  }
`

export const ProfileImage = styled(Image)`
  width: 100%;
  margin-bottom: 10px;
`

export const ContactInfo = styled.div`
  line-height: 1.2em;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
`

export const Name = styled.div`
  font-weight: bold;
`

export const Website = styled.a`
  display: block;
  margin-bottom: 10px;
`

export const NewsletterSignup = styled.a`
  border: none;
  border-radius: 2px;
  display: block;
  font-size: 1em;
  font-weight: 700;
  padding: 10px;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 767px) {
    font-size: 0.875em;
  }
`
