/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState, useEffect } from "react";
import { gql } from "@apollo/client";
import { DateTime } from "luxon";

import useSweepstakesQuery from "../lib/useSweepstakesQuery";

export const Context = React.createContext();

const PERIODS = [ "review", "open", "closing" , "grace", "closed" , "drawn", "archived" ];

// https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout#Notes
// Set a safe limit of about 28 days to avoid overflowing setTimeout and executing immediately.
const SAFE_LIMIT = 24 * 24 * 60 * 60 * 1000;

const getSweepstakesSchedule = gql`
  query CheckSweepstakes($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      schedule {
        review
        open
        closing
        grace
        closed
        drawn
        archived
      }
    }
  }
`;

const findCurrentPeriod = schedule => PERIODS.find(name => {
  const period = schedule[name];

  const beginsAt = period[0] === null ? null : DateTime.fromISO(period[0]);
  const endsAt   = period[1] === null ? null : DateTime.fromISO(period[1]);

  if(beginsAt === null && endsAt === null) {
    return true;
  }

  // Null is open-ended, any time before end of period.
  if(beginsAt === null) {
    return DateTime.local() < endsAt;
  }

  // Null is open-ended, any time after beginning of period.
  if(endsAt === null) {
    return DateTime.local() > beginsAt;
  }

  return beginsAt < DateTime.local() && DateTime.local() < endsAt;
});

const timeUntilEnd = period => {
  const endsAt = period[1] === null ? null : DateTime.fromISO(period[1]);

  if(endsAt === null) {
    return null;
  }

  return Math.min(endsAt.diffNow().milliseconds, SAFE_LIMIT)
};

const WithTimer = ({ schedule, children }) => {
  const [ currentPeriod, setCurrentPeriod ] = useState(findCurrentPeriod(schedule));

  const tryStateUpdate = () => {
    const nextPeriod = findCurrentPeriod(schedule);
    setCurrentPeriod(nextPeriod);

    const timeout = timeUntilEnd(schedule[nextPeriod]);

    if(timeout) {
      setTimeout(tryStateUpdate, timeout);
    }
  };

  useEffect(() => {
    const timeout = timeUntilEnd(schedule[currentPeriod]);

    if(timeout) {
      setTimeout(tryStateUpdate, timeout);
    }
  }, []);

  return <Context.Provider value={currentPeriod} children={children} />;
};

export const ScheduleProvider = ({ children }) => {
  const { loading, data, error } = useSweepstakesQuery(getSweepstakesSchedule)

  if(loading || error) {
    return null;
  }

  const { sweepstakes: { schedule }} = data;

  return <WithTimer schedule={schedule} children={children} />
};

export const useSchedulePeriod = () => useContext(Context);

export const ScheduleMask = ({ enabledPeriods, children }) => {
  const currentPeriod = useContext(Context);

  if(enabledPeriods.includes(currentPeriod)) {
    return children;
  }

  return null;
};

export const ScheduleGuard = ({ enabledPeriods, children }) => {
  const currentPeriod = useContext(Context);

  const newProps = {
    guarded: !enabledPeriods.includes(currentPeriod),
  };

  return React.cloneElement(children, newProps);
};

export const ScheduleSwitch = ({ children }) => {
  const period = useContext(Context);
  let element = null;

  React.Children.forEach(children, child => {
    if (element === null && React.isValidElement(child)) {
      if(child.props.periods === period || (child.props.periods.includes && child.props.periods.includes(period))) {
        element = child;
      }
    }
  });

  return element;
};
