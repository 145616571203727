import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { Honeybadger } from '@honeybadger-io/react'

import { ToolContext, DONATION_LEVEL_STEP, DONOR_INFO_STEP } from "./ToolProvider";
import Layout from "../Layout";
import * as UI from "./ui";
import DonationLevels from "./DonationLevels";
import DonorForm from "./DonorForm";
import Switch from "./Switch"
import { setupPaymentIntentMutation } from "./mutations";
import ClosedContent from "./ClosedContent";

const ERROR = "Uh-oh! Sorry, but something has gone wrong. To try again please submit the form again. We’ve notified our support team of this issue.";

const notifyFailure = (error) => {
  Honeybadger.notify(error);
}

const InputStep = ({ sweepstakes, onComplete }) => {
  const { donationLevel, promoCode, paymentIntent, setPaymentIntent, setStep, expired } = useContext(ToolContext);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ donationLevelError, setDonationLevelError ] = useState('');
  const [ setupPaymentIntent, { loading }] = useMutation(setupPaymentIntentMutation);

  if(expired) {
    return <ClosedContent sweepstakes={sweepstakes} />
  }

  const saveDonation = () => {
    setErrorMessage('');
    setDonationLevelError('');

    if(!donationLevel) {
      setDonationLevelError("Please select an amount before continuing.");
      return;
    }

    setupPaymentIntent({
      variables: {
        attributes: {
          id: paymentIntent && paymentIntent.id,
          donationLevelId: donationLevel.id,
          promoCodeName: promoCode && promoCode.name,
        }
      }
    }).then(({data: { setupPaymentIntent: { paymentIntent, error }}}) => {
      if(paymentIntent) {
        setPaymentIntent(paymentIntent);
        onComplete();
      } else {
        notifyFailure(error.message);
        setErrorMessage(ERROR);
      }
    });
  }

  return (
    <Layout.FullWidth fold>
      <Switch enableDesktopUnfold>
        <DonationLevels
          steps={DONATION_LEVEL_STEP}
          donationLevels={sweepstakes.donationLevels}
          onComplete={() => setStep(DONOR_INFO_STEP)}
          error={donationLevelError}
        />
        <DonorForm
          steps={DONOR_INFO_STEP}
          setDonorField={() => {}}
          onComplete={saveDonation}
          disabled={loading}
        />
      </Switch>
      <Layout.FullWidthColumn>
        { errorMessage && <UI.ErrorMessage>{errorMessage}</UI.ErrorMessage> }
      </Layout.FullWidthColumn>
    </Layout.FullWidth>
  );
};

export default InputStep;
