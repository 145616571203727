import React, { useContext } from "react";
import { assocPath } from "ramda";

import { ToolContext } from "./ToolProvider";
import Layout from "../Layout";
import Field from "./Field";
import Select from "./Select";
import * as UI from "./ui";
import countries from "../../lib/countries";

const DonorForm = ({ disabled, onComplete }) => {
  const { donor, setDonor } = useContext(ToolContext);

  const onSubmit = e => {
    e.preventDefault();
    onComplete();
  };

  const setDonorField = path => e => {
    setDonor(current => assocPath(path, e.target.value, current));
  };

  return (
    <Layout.FlexibleColumn span={[7,15]} breakpoint="belowLaptop">
      <UI.SubstepHeading>Redeem Sweepstakes Entry Without a Donation</UI.SubstepHeading>
      <UI.Form onSubmit={onSubmit}>
        <UI.Instructions>To receive a sweepstakes entry without a donation, fill in the entire form.</UI.Instructions>
        <UI.FieldGroup>
          <Field id="firstName" label="First Name" onChange={setDonorField(["firstName"])} value={donor.firstName} required />
          <Field id="lastName" label="Last Name" onChange={setDonorField(["lastName"])} value={donor.lastName} required />
        </UI.FieldGroup>
        <Field type="email" id="email" label="Email" onChange={setDonorField(["email"])} value={donor.email} required />
        <Field type="tel" id="phoneNumber" label="Phone" onChange={setDonorField(["phoneNumber"])} value={donor.phoneNumber} required />
        <UI.FieldGroup>
          <Field id="address.line1" label="Address" onChange={setDonorField(["address", "line1"])} value={donor.address.line1} weight={3} required />
          <Field id="address.line2" label="Apt #" onChange={setDonorField(["address", "line2"])} value={donor.address.line2} weight={2} />
        </UI.FieldGroup>
        <UI.FieldGroup>
          <Field id="address.city" label="City" onChange={setDonorField(["address", "city"])} value={donor.address.city} weight={2} required />
          <Field id="address.state" label="State" onChange={setDonorField(["address", "state"])} value={donor.address.state} required />
          <Field id="address.postalCode" label="Zip" onChange={setDonorField(["address", "postalCode"])} value={donor.address.postalCode} required />
        </UI.FieldGroup>
        <Select id="address.country" label="Country" onChange={setDonorField(["address", "country"])} value={donor.address.country} options={countries} required />
        <UI.Legal>* By continuing, I certify I am 18 years of age or older, eligible to participate in this sweepstakes, and I agree to abide by the sweepstakes rules.</UI.Legal>
        <UI.FieldGroup>
          <UI.ActionButton value="Redeem" disabled={disabled} />
        </UI.FieldGroup>
      </UI.Form>
    </Layout.FlexibleColumn>
  );
};

export default DonorForm;
