import { Cloudinary } from "cloudinary-core";

export const cloudinaryUrl = (image, transformation: []) => {
  const cloudinary = new Cloudinary({ cloud_name: image.cloudName });

  const options = {
    transformation,
    version: image.version
  };

  return cloudinary.url(image.publicId, options);
};

// https://developers.facebook.com/docs/sharing/webmasters/images/
const openGraphTransformation = [
  {
    width: 1200,
    height: 630,
    crop: "fill",
    quality: "auto:good"
  },
];

export const openGraphCloudinaryUrl = image => cloudinaryUrl(image, openGraphTransformation);
