import React from 'react';

import Layout from "../Layout";
import PrizeDetails from "./PrizeDetails";
import ReadMore from "../../lib/ReadMore";

import WinnerDetails from './WinnerDetails';
import Overview from './Overview';
import Copy from '../Copy';

const AboutSweepstakes = ({sweepstakes}) => (
  <Layout.FullWidth fold>
    <Layout.FlexibleColumn span={[7,15]} breakpoint="tablet" spaced>
      <WinnerDetails sweepstakes={sweepstakes} />
      <ReadMore description="Sweepstakes Description">
        <Copy markdown={sweepstakes.description} />
      </ReadMore>
      { sweepstakes.prizeDetails && <PrizeDetails prizeDetails={sweepstakes.prizeDetails} /> }
    </Layout.FlexibleColumn>

    <Layout.FixedColumn span={5} breakpoint="tablet" spaced>
      <Overview sweepstakes={sweepstakes} />
    </Layout.FixedColumn>
  </Layout.FullWidth>
);

export default AboutSweepstakes;
