import React from 'react';

import SweepstakesHeadTags from "../SweepstakesHeadTags";
import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import { ScheduleMask } from "../../lib/schedule";
import Page from "../Page";
import Hero from "../Hero"
import AnnouncementBar from "../AnnouncementBar";
import AboutNonprofit from '../AboutNonprofit';
import AboutSweepstakes from '../AboutSweepstakes';
import SponsorSection from "../SponsorSection";
import SuperFooter from '../SuperFooter';
import FloatingAction from "../../lib/FloatingAction";
import DonateNow from "../DonateNow";
import Layout from "../Layout";
import { sweepstakesQuery } from "./queries";
import * as UI from "./ui";

const IndexPage = () => {
  const { data, loading, error } = useSweepstakesQuery(sweepstakesQuery)

  if(loading || error) {
    return null;
  }

  const { sweepstakes } = data;

  return (
    <Page>
      <SweepstakesHeadTags sweepstakes={sweepstakes} />

      <header>
        <UI.Title>{sweepstakes.headline}</UI.Title>
      </header>

      <ScheduleMask enabledPeriods={["review", "open", "closing" , "grace", "closed" , "drawn"]}>
        <AnnouncementBar sweepstakes={sweepstakes} />
      </ScheduleMask>

      <Hero sweepstakes={sweepstakes} />

      <ScheduleMask enabledPeriods={["review", "open", "closing" , "grace", "closed" , "drawn"]}>
        <AboutSweepstakes sweepstakes={sweepstakes} />

        <Layout.Divider />

        <AboutNonprofit
          nonprofit={sweepstakes.nonprofit}
        />

        <SponsorSection
          sweepstakes={sweepstakes}
        />

        <SuperFooter
          sweepstakes={sweepstakes}
        />
      </ScheduleMask>

      <ScheduleMask enabledPeriods={["open", "closing"]}>
        <FloatingAction>
          <DonateNow floating />
        </FloatingAction>
      </ScheduleMask>
    </Page>
  );
}

export default IndexPage;
