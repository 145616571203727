import { gql } from "@apollo/client";

export const receiptQuery = gql`
  query ReceiptQuery($id: ID!) {
    receipt(id: $id) {
      id
      rewardTicketCount
      promoCodeTicketCount
      ticketRanges {
        min
        max
      }
    }
  }
`;
