import React from "react";
import useDimensions from "react-use-dimensions";

import * as UI from "./ui";
import NumberWheel from "../../../lib/NumberWheel";

const DELAY_PER_NUMBER = 500;

const calcCardWidth = (width, padding) => {
  if(!width) {
    return null;
  }

  // Card width is width of parent container subtracting padding from either side.
  if(width < 768) {
    return Math.max(40, Math.floor(width / padding) - 24)
  }

  return Math.floor(width / padding) - 48;
};

const NumberWheelScene = ({ length, sweepstakes }) => {
  const [ref, { width }] = useDimensions({ liveMeasure: false });
  const targetNumber = sweepstakes.prize.winnerSnapshot.number;
  const padding = Math.max(4, sweepstakes.prize.winnerSnapshot.number.length);
  const targetArray = targetNumber.toString().padStart(padding, "0").split("").reverse();

  const cardWidth = calcCardWidth(width, padding);

  const maxDelay = (targetArray.length - 1) * DELAY_PER_NUMBER;

  // Duration is fractional length of scene less longest delay
  const duration = Math.floor((length * 1000) / 1.5) - maxDelay;

  return (
    <UI.Layout>
      <UI.NumberWheelLayout duration={length * 1000}>
        <UI.Heading>And the winning entry is…</UI.Heading>
        <UI.NumberWheelsBackground>
          <UI.NumberWheels ref={ref}>
            { cardWidth && (
              targetArray.map((target,i) => (
                <UI.Slot small={width < 768}>
                  <NumberWheel
                    key={i}
                    target={target}
                    cardWidth={cardWidth}
                    duration={duration} 
                    delay={i * DELAY_PER_NUMBER}
                  />
                </UI.Slot>
              ))
            )}
          </UI.NumberWheels>
        </UI.NumberWheelsBackground>
      </UI.NumberWheelLayout>
    </UI.Layout>
  );
}

export default NumberWheelScene;
