import React from "react";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import ToolProvider from "./ToolProvider";
import Tool from "./Tool";

const DonationTool = (props) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
    stripeAccount: props.nonprofit.paymentAccountId,
    apiVersion: process.env.REACT_APP_STRIPE_API_VERSION,
  });

  return (
    <Elements stripe={stripePromise}>
      <ToolProvider bootstrap={props.bootstrap} expired={props.expired}>
        <Tool {...props} />
      </ToolProvider>
    </Elements>
  );
}

export default DonationTool;
