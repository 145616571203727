import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Background = styled.div`
  background-color: #f9f9f9;
  border-color: #efefef;

  @media print { 
    display: none;
  }
`;

export const Layout = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 0;
`

export const Widget = styled.div`
  @media screen and (max-width: 767px) {
    text-align: center;
  }
`

export const WidgetTitle = styled.div`
  font-weight: 300;
  font-size: 0.625em;
  line-height: 1.6em;
  text-transform: uppercase;
`

export const WidgetValue = styled.div`
  font-size: ${props => props.shrink ? '0.75em' : '1.25em'};
  font-weight: 600;
  margin: 5px 0;
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    justify-content: center;
  }

  svg {
    margin-right: 5px;
  }
`

export const TotalIcon = styled(FontAwesomeIcon)`
  color: #f40028;;
`;

export const ThresholdIcon = styled(FontAwesomeIcon)`
  color: ${props => props.met ? '#00C19D' : '#ffa239'};
`;
