import React from 'react';

import Layout from "../Layout";
import ReadMore from "../../lib/ReadMore";
import { pickTextColor } from "../../lib/color";
import Copy from '../Copy';
import * as UI from "./ui";

const AboutNonprofit = ({ nonprofit }) => {
  const styles = {
    backgroundColor: nonprofit.profile.color,
    color: pickTextColor({ background: nonprofit.profile.color, light: '#fff', dark: '#000' }),
  };

  return (
    <Layout.FullWidth fold>

      <Layout.FixedColumn span={8} breakpoint="tablet" spaced>
        <UI.ProfileImage
          source={nonprofit.profile.image}
          aspectRatio="16:9"
          width="auto"
          dpr="auto"
          fetchFormat="auto"
          responsive
          alt={`About ${nonprofit.name}`}
        />
        <UI.ContactInfo>
          <UI.Name>{nonprofit.name}</UI.Name>
          <div>{nonprofit.address.line1} {nonprofit.address.line2}</div>
          <div>{nonprofit.address.city}, {nonprofit.address.state} {nonprofit.address.postalCode}</div>
          <div>{nonprofit.profile.phone}</div>
        </UI.ContactInfo>
      </Layout.FixedColumn>

      <Layout.FlexibleColumn span={[4,12]} breakpoint="tablet" spaced>
        <ReadMore description="Nonprofit Description">
          <Copy markdown={nonprofit.profile.description} />
        </ReadMore>
        <UI.Website href={nonprofit.profile.website} target="_blank" rel="noopener noreferrer">Visit Our Website</UI.Website>
        { nonprofit.profile.newsletterWebsite && (
          <UI.NewsletterSignup href={nonprofit.profile.newsletterWebsite} target="_blank" rel="noopener noreferrer" style={styles}>
            Sign up for sweepstakes updates and offers
          </UI.NewsletterSignup>
        )}
      </Layout.FlexibleColumn>

    </Layout.FullWidth>
  );
}

export default AboutNonprofit;
