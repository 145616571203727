import { checkSweepstakesExists } from "./queries";
import useSweepstakesQuery from "../../lib/useSweepstakesQuery";

const SweepstakesExistsGuard = ({ children }) => {
  const { loading, data, error } = useSweepstakesQuery(checkSweepstakesExists)

  if(loading || error) {
    return null;
  }

  const { sweepstakes } = data;

  if(sweepstakes) {
    return children;
  }

  window.location.assign(process.env.REACT_APP_MISSING_SWEEPSTAKES_DESTINATION)

  return null;
};


export default SweepstakesExistsGuard;
