import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import SweepstakesHeadTags from "../SweepstakesHeadTags";
import { useSchedulePeriod } from "../../lib/schedule";
import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import { donationToolQuery } from "./gql";
import InPersonDonationTool from "../InPersonDonationTool";

const InPersonDonationPage = () => {
  const period = useSchedulePeriod();
  const navigate = useNavigate();

  useEffect(() => {
    if (["drawn", "archived"].includes(period)) {
      navigate("/", { replace: true });
    }
  }, [period, navigate]);

  const { data, loading, error } = useSweepstakesQuery(donationToolQuery);

  if(loading || error) {
    return null;
  }

  const {
    sweepstakes,
    sweepstakes: {
      nonprofit
    },
  } = data;

  return (
    <React.Fragment>
      <SweepstakesHeadTags sweepstakes={sweepstakes} title={`In-Person Donation | ${sweepstakes.headline}`} />
      <InPersonDonationTool
        sweepstakes={sweepstakes}
        nonprofit={nonprofit}
      />
    </React.Fragment>
  );
};

export default InPersonDonationPage;
