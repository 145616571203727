/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { DateTime } from "luxon";
import { faClock } from "@fortawesome/free-solid-svg-icons";

import { ScheduleSwitch } from "../../../lib/schedule";
import * as UI from "./ui";

const pluralize = (value) => (
  (value === 0 || value > 1) ? 's' : ''
);

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;

const MODES = {
  monthsLeft: {
    display: () => null,
    nextTick: HOUR,
  },
  daysLeft: {
    display: remaining => `${Math.ceil(remaining.as("days"))} Day${pluralize(remaining.as('days'))} Left`,
    nextTick: SECOND,
  },
  hoursLeft: {
    display: remaining => `${Math.ceil(remaining.as('hours'))} Hour${pluralize(remaining.as('hours'))} Left`,
    nextTick: SECOND,
  },
  minutesLeft: {
    display: remaining => `${remaining.toFormat("mm:ss")} Left`,
    nextTick: SECOND,
  },
};

const findModeName = remaining => {
  if(remaining.as('days') > 30) {
    return 'monthsLeft';
  }

  if(remaining.as('days') > 1) {
    return 'daysLeft';
  }

  if(remaining.as('hours') > 1) {
    return 'hoursLeft';
  }

  if(remaining.as('minutes') > 0) {
    return 'minutesLeft';
  }
}

const CountdownTimer = ({ period }) => {
  let timerId;
  const endsAt = DateTime.fromISO(period[1]);

  const [ remaining, setRemaining ] = useState(endsAt.diffNow());
  const [ modeName, setModeName ] = useState(findModeName(remaining));

  const mode = MODES[modeName];

  const tick = () => {
    const nextRemaining = endsAt.diffNow();
    const nextModeName = findModeName(nextRemaining);

    const nextMode = MODES[nextModeName];

    setRemaining(nextRemaining);
    setModeName(nextModeName);

    if(nextMode && nextMode.nextTick) {
      timerId = setTimeout(tick, nextMode.nextTick);
    }
  };

  useEffect(() => {
    if(mode.nextTick) {
      timerId = setTimeout(tick, mode.nextTick);
    }

    return () => {
      if(timerId) { 
        clearTimeout(timerId);
      }
    };
  }, []);

  const display = mode.display(remaining);

  return display && (
    <UI.Widget><UI.Icon icon={faClock} /> {display}</UI.Widget>
  );
}

const CountdownClosed = ({ drawTime, timezone }) => (
  <UI.Widget>
    Sweepstakes has closed. The winner will be drawn on {DateTime.fromISO(drawTime, { zone: timezone }).toLocaleString(DateTime.DATETIME_FULL)}
  </UI.Widget>
)

const CountdownDrawn = ({ winnerPresentationUrl }) => (
  <UI.Widget>
    <a href={winnerPresentationUrl}>View the Winner Presentation!</a>
  </UI.Widget>
);

const CountdownWidget = ({ sweepstakes }) => (
  <ScheduleSwitch>
    <CountdownTimer periods={["open", "closing"]} period={sweepstakes.schedule.closing} />
    <CountdownClosed periods={["grace", "closed"]} timezone={sweepstakes.timezone} drawTime={sweepstakes.winningTicketDrawnAt} />
    <CountdownDrawn periods={["drawn"]} winnerPresentationUrl={sweepstakes.winnerPresentationUrl} />
  </ScheduleSwitch>
);

export default CountdownWidget;
