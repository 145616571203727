import { gql } from "@apollo/client";

export const createMailinTicketRequestMutation = gql`
  mutation CreateMailinTicketRequest($sweepstakesId: SweepstakesSlug!, $attributes: MailinTicketRequestInput!, $captcha: String!) {
    createMailinTicketRequest(sweepstakesId: $sweepstakesId, attributes: $attributes, captcha: $captcha) {
      mailinTicketRequest {
        id
        downloadUrl
      }
      error {
        code
        message
      }
    }
  }
`;
