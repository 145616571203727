import React, { useState } from "react";

import Layout from "../Layout";
import Field from "./Field";
import Select from "./Select";
import * as UI from "./ui";
import countries from "../../lib/countries";

const INITIAL_REQUEST = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  address: {
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "US",
  }
}

const Form = ({ disabled, onSubmit }) => {
  const [ mailinTicketRequest, setMailinTicketRequest ] = useState(INITIAL_REQUEST);

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(mailinTicketRequest);
  };

  const setMailinTicketRequestField = id => e => {
    const value = e.target.value;

    setMailinTicketRequest(current => ({
      ...current,
      [id]: value,
    }));
  };

  const setAddressField = id => e => {
    const value = e.target.value;

    setMailinTicketRequest(current => ({
      ...current,
      address: {
        ...current.address,
        [id]: value,
      },
    }));
  }

  return (
    <Layout.FlexibleColumn span={[7,15]} breakpoint="belowLaptop">
      <UI.Form onSubmit={handleSubmit}>
        <UI.FieldGroup>
          <Field id="firstName" label="First Name" onChange={setMailinTicketRequestField("firstName")} value={mailinTicketRequest.firstName} required />
          <Field id="lastName" label="Last Name" onChange={setMailinTicketRequestField("lastName")} value={mailinTicketRequest.lastName} required />
        </UI.FieldGroup>
        <Field type="email" id="email" label="Email" onChange={setMailinTicketRequestField("email")} value={mailinTicketRequest.email} required />
        <Field type="tel" id="phoneNumber" label="Phone" onChange={setMailinTicketRequestField("phoneNumber")} value={mailinTicketRequest.phoneNumber} required />
        <UI.FieldGroup>
          <Field id="address.line1" label="Address" onChange={setAddressField("line1")} value={mailinTicketRequest.address.line1} weight={3} required />
          <Field id="address.line2" label="Apt #" onChange={setAddressField("line2")} value={mailinTicketRequest.address.line2} weight={2} />
        </UI.FieldGroup>
        <UI.FieldGroup>
          <Field id="address.city" label="City" onChange={setAddressField("city")} value={mailinTicketRequest.address.city} weight={2} required />
          <Field id="address.state" label="State" onChange={setAddressField("state")} value={mailinTicketRequest.address.state} required />
          <Field id="address.postalCode" label="Zip / Postal Code" onChange={setAddressField("postalCode")} value={mailinTicketRequest.address.postalCode} required />
        </UI.FieldGroup>
        <Select id="address.country" label="Country" onChange={setAddressField("country")} value={mailinTicketRequest.address.country} options={countries} required />
        <UI.Legal>* By continuing, I certify I am 18 years of age or older, eligible to participate in this sweepstakes, and I agree to abide by the sweepstakes rules.</UI.Legal>
        <UI.FieldGroup>
          <UI.ActionButton value="Submit Request" disabled={disabled} />
        </UI.FieldGroup>
      </UI.Form>
    </Layout.FlexibleColumn>
  );
};

export default Form;
