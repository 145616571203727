import { gql } from "@apollo/client";

export const winnerQuery = gql`
  query GetWinnerInfo($id: SweepstakesSlug!) {
    sweepstakes(id: $id){
      id
      prize {
        id
        winnerSnapshot {
          name
          location
          number
          drawnAt
        }
      }
    }
  }
`;

