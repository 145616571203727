import styled from "styled-components";
import { Image } from "cloudinary-react";

export const Heading = styled.h3`
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25em;

  @media screen and (min-width: 767px) {
    font-size: 1.125em;
    line-height: 1.125em;
  }

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

export const Sponsor = styled.a`
  display: block;
`;

export const Logo = styled(Image)`
  display: block;
  max-width: 100%;
`;
