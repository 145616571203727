import { toMoneyString } from "../../Money";

const trackSuccessWithGTag = ({ paymentIntent, donationLevel, promoCode, sweepstakes }) => {
  window?.gtag('event', 'purchase', {
    event_category: 'sweepstakes',
    event_label: 'donation',
    currency: paymentIntent.currency,
    value: toMoneyString({ cents: paymentIntent.amount, format: '0.00'}),
    transaction_id: paymentIntent.id,
    coupon: promoCode && promoCode.name,
    items: [
      {
        item_id: donationLevel.id,
        item_name: `${toMoneyString({ cents: paymentIntent.amount })} Donation`,
      }
    ],
  });
};

const trackSuccessWithGoogleAds = ({ paymentIntent, donationLevel, promoCode, sweepstakes }) => {
  if(sweepstakes.googleAdsConversionId) {
    window?.gtag('event', 'conversion', {
      send_to: sweepstakes.googleAdsConversionId,
      value: toMoneyString({ cents: paymentIntent.amount, format: '0.00'}),
      currency: paymentIntent.currency,
      transaction_id: paymentIntent.id,
    });
  }
};

const trackSuccessWithFacebook = ({ paymentIntent, donationLevel }) => {
  window?.fbq('track', 'Purchase', {
    value: toMoneyString({ cents: paymentIntent.amount, format: '0.00'}),
    currency: paymentIntent.currency,
    content_ids: [donationLevel.id],
  });
};

export const trackSuccess = ({ paymentIntent, donationLevel, promoCode, sweepstakes, donor }) => {
  trackSuccessWithGTag({ paymentIntent, donationLevel, promoCode, sweepstakes });
  trackSuccessWithGoogleAds({ paymentIntent, donationLevel, promoCode, sweepstakes });
  trackSuccessWithFacebook({ paymentIntent, donationLevel, promoCode, sweepstakes });
};
