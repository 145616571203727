import React, { useEffect } from "react";
import confetti from "canvas-confetti";

import * as UI from "./ui";

const launchRandom = () => {
  confetti({
    gravity: 0.4,
    origin: {
      x: Math.random(),
      y: Math.random()
    },
    particleCount: 50,
    spread: 360,
    startVelocity: 30,
    ticks: 50,
    scalar: 2,
  });
};

let intervalId, timeoutId;

const WinnerSnapshotScene = ({ length, sweepstakes, onReplay }) => {

  useEffect(() => { 
    intervalId = setInterval(() => {
      launchRandom();
      launchRandom();
    }, 800);

    timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 30 * 1000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    }
  }, []);

  const handleReplay = () => {
    confetti.reset();
    onReplay();
  }

  const { prize: { winnerSnapshot } } = sweepstakes;

  const padding = Math.max(4, winnerSnapshot.numberPadding);
  const winningNumber = winnerSnapshot.number.toString().padStart(padding, "0");

  return (
    <UI.Layout>
      <UI.Card>
        <UI.Heading>The Winning Entry</UI.Heading>
        <UI.WinningNumber length={length}>#{winningNumber}</UI.WinningNumber>
        <UI.WinnerInfo length={length}>
          <UI.Name>{winnerSnapshot.name}</UI.Name>
          <UI.Location>{winnerSnapshot.location}</UI.Location>
        </UI.WinnerInfo>
      </UI.Card>
      <UI.Replay onClick={handleReplay}>Replay</UI.Replay>
    </UI.Layout>
  );
}

export default WinnerSnapshotScene;
