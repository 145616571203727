import { gql } from "@apollo/client";

export const donationToolQuery = gql`
  query DonationToolQuery($id: SweepstakesSlug!, $promoCodeName: String) {
    sweepstakes(id: $id) {
      id
      headline
      googleAdsConversionId

      slides {
        id
        image {
          publicId
          version
          cloudName
        }
      }

      nonprofit {
        id
        ein
        name
        paymentAccountId

        profile {
          id
          color
        }
      }

      donationLevels {
        id
        price
        level
        rewardTickets
      }

      schedule {
        id
        grace
      }

      activeThankYouCampaign {
        id
        smallImageUrl
        largeImageUrl
        promoCode {
          id
          name
        }
      }
    }

    promoCode(id: $id, name: $promoCodeName) {
      id
      name
      bonusPercent
    }
  }
`;

