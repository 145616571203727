import React from 'react';
import { Navigate } from "react-router-dom";

import Page from "../Page";
import SweepstakesHeadTags from "../SweepstakesHeadTags";
import useSweepstakesQuery from '../../lib/useSweepstakesQuery';
import { ScheduleSwitch } from "../../lib/schedule";
import OpenContent from "./OpenContent";
import ClosedContent from "./ClosedContent";
import { pageSetupQuery } from "./gql";

const AlternativeMethodOfEntryPage = () => {
  const { data, loading, error } = useSweepstakesQuery(pageSetupQuery)

  if(loading || error) {
    return null;
  }

  const { sweepstakes } = data;

  return (
    <Page>
      <SweepstakesHeadTags sweepstakes={sweepstakes} title={`Alternative Method of Entry | ${sweepstakes.headline}`} />
      <ScheduleSwitch>
        <Navigate to="/" periods={["review"]} />
        <OpenContent sweepstakes={sweepstakes} periods={["open"]} />
        <ClosedContent periods={["closing", "grace", "closed", "drawn", "archived"]} />
      </ScheduleSwitch>
    </Page>
  );
};

export default AlternativeMethodOfEntryPage;
