import React from "react";

import { pickTextColor } from "../../lib/color";
import Guide from "./Guide";
import Footer from "../Footer";
import * as UI from "./ui";

const Page = ({ sweepstakes, nonprofit, children }) => {
  const styles = {
    backgroundColor: nonprofit.profile.color,
    color: pickTextColor({ background: nonprofit.profile.color, light: '#fff', dark: '#000' }),
  };

  const backButtonStyles = {
    borderRightColor: pickTextColor({ background: nonprofit.profile.color, light: '#fff', dark: '#000' }),
  };

  return (
    <UI.LayoutWrwapper>
      <UI.Layout>
        <UI.Header>
          <UI.Nonprofit style={styles}>
            <UI.HeaderContent>
              <UI.Back to="/" style={backButtonStyles}></UI.Back>
              <UI.Name>{nonprofit.name}</UI.Name>
            </UI.HeaderContent>
          </UI.Nonprofit>
        </UI.Header>
        <Guide />
        <UI.Body>
          {children}
        </UI.Body>
        <Footer />
      </UI.Layout>
    </UI.LayoutWrwapper>
  );
}

export default Page;
